import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useAppSelector } from '../../Redux/Store/store'
import { imageUrl } from '../../Redux/Api/paths'

const StyledCard = styled(Card)({
  width: '100%',
})

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '56.25%',
})

const EducatorInfo = () => {
  const educator = useAppSelector((state) => state.userSlice.educator)
  console.log(educator?.image)
  return (
    <StyledCard>
      <StyledCardMedia
        image={imageUrl + educator?.image}
        title={educator?.firstName}
      />
      <CardContent>
        <Typography variant="h5" component="h2">
          {`${educator?.firstName} ${educator?.lastName}`}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {educator?.group?.name}
        </Typography>
      </CardContent>
    </StyledCard>
  )
}

export default EducatorInfo
