import React from 'react'
import { Box, Drawer, IconButton } from '@mui/material'
import { styled } from '@mui/system'

export const DrawerStyledComponent = styled(Drawer)(({ theme }) => ({
  width: 240,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 240,
    boxSizing: 'border-box',
  },
}))

export const DrawerMenuIcon = styled(IconButton)(({ theme }) => ({
  color: 'white',
  marginLeft: 'auto',
}))
