import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { IAlert } from '../../../Types/types'
import { Alert, Button } from '@mui/material'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import { AddAlertFormConfig } from '../AddAlert/AddAlert.constants'
import {
  AddButton,
  AddModal,
  AddModalBox,
  CustomAddIcon,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAllAlertsQuery,
  useUpdateAlertMutation,
} from '../../../Redux/Api/adminApi'

interface IUpdateChildProps {
  onCloseModelHandler: () => void
  alert: IAlert | undefined
}

export const UpdateAlert: React.FC<IUpdateChildProps> = ({
  onCloseModelHandler,
  alert,
}) => {
  const [updateAlert, { isSuccess, isError }] = useUpdateAlertMutation()
  const { refetch } = useAllAlertsQuery()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAlert>({
    defaultValues: {
      id: alert?.id,
      title: alert?.title,
      message: alert?.message,
      start_date: alert?.start_date,
      end_date: alert?.end_date,
    },
  })

  const onSubmitHandler: SubmitHandler<IAlert> = (alert) => {
    updateAlert(alert)
  }

  useEffect(() => {
    if (isSuccess) {
      onCloseModelHandler()
      refetch()
    }
  }, [isSuccess, refetch, onCloseModelHandler])
  return (
    <AddModal open onClose={onCloseModelHandler}>
      <AddModalBox onSubmit={handleSubmit(onSubmitHandler)} component="form">
        <TextInput
          label="title"
          options={{
            ...register('title', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.title.required),
              },
              minLength: {
                value: AddAlertFormConfig.title.minLength.value,
                message: t(AddAlertFormConfig.title.minLength.message),
              },
            }),
          }}
        />
        {errors.title && <Alert severity="error">{errors.title.message}</Alert>}
        <TextInput
          label="Message"
          options={{
            ...register('message', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.message.required),
              },
              minLength: {
                value: AddAlertFormConfig.message.minLength.value,
                message: t(AddAlertFormConfig.message.minLength.message),
              },
            }),
          }}
        />
        {errors.message && (
          <Alert severity="error">{errors.message.message}</Alert>
        )}
        <TextInput
          label="Start Date"
          options={{
            ...register('start_date', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.start_date.required),
              },
            }),
          }}
        />
        {errors.start_date && (
          <Alert severity="error">{errors.start_date.message}</Alert>
        )}
        <TextInput
          label="End Date"
          options={{
            ...register('end_date', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.end_date.required),
              },
            }),
          }}
        />
        {errors.end_date && (
          <Alert severity="error">{errors.end_date.message}</Alert>
        )}
        <AddButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          startIcon={<CustomAddIcon />}
        >
          {t('Update')}
        </AddButton>
      </AddModalBox>
    </AddModal>
  )
}
