export const AddActivityFormConfig = {
  title: {
    required: 'AddActivityFormConfig.title.required',
    minLength: {
      value: 4,
      message: 'AddActivityFormConfig.title.minLength.message',
    },
  },
  description: {
    required: 'AddActivityFormConfig.description.required',
    minLength: {
      value: 50,
      message: 'AddActivityFormConfig.description.minLength.message',
    },
  },

  images: {
    required: true,
  },
}
