import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  IGenericResponse,
  Child,
  IUser,
  Educator,
  Group,
  Activity,
  IAssignEducator,
  IAssignChild,
  ListResponse,
  IAlert,
  SearchParams,
} from '../../Types/types'
import { RootState } from '../Store/store'
import {
  backendPath,
  allChildren,
  registerChild,
  updateChild,
  allEducators,
  deleteChild,
  activityById,
  allParents,
  deleteParent,
  deleteEducator,
  allGroups,
  deleteGroup,
  deleteActivity,
  allActivities,
  addEducator,
  createGroup,
  totalChildren,
  totalEducators,
  totalParents,
  createAlert,
  getAllAlerts,
  deleteAlert,
  updateAlert,
  filteredActivities,
} from './paths'

export const adminApi = createApi({
  reducerPath: 'educatorPath',
  baseQuery: fetchBaseQuery({
    baseUrl: backendPath,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['admin'],
  endpoints: (builder) => ({
    totalParents: builder.query<IGenericResponse, void>({
      query: () => totalParents,
      providesTags: ['admin'],
    }),
    totalChildren: builder.query<IGenericResponse, void>({
      query: () => totalChildren,
      providesTags: ['admin'],
    }),
    totalEducators: builder.query<IGenericResponse, void>({
      query: () => totalEducators,
      providesTags: ['admin'],
    }),
    allParents: builder.query<ListResponse<IUser>, number | void>({
      query: (page = 1) => `${allParents}?page=${page}`,
      providesTags: ['admin'],
    }),
    allEducators: builder.query<IGenericResponse, void>({
      query: () => allEducators,
      providesTags: ['admin'],
    }),
    allChildren: builder.query<ListResponse<Child>, number | void>({
      query: (page = 1) => `${allChildren}?page=${page}`,
      providesTags: ['admin'],
    }),
    allGroups: builder.query<IGenericResponse, void>({
      query: () => allGroups,
      providesTags: ['admin'],
    }),
    allActivities: builder.query<ListResponse<Activity>, number | void>({
      query: (page = 1) => `${allActivities}?page=${page}`,
      providesTags: ['admin'],
    }),
    allAlerts: builder.query<ListResponse<IAlert>, number | void>({
      query: (page = 1) => `${getAllAlerts}?page=${page}`,
      providesTags: ['admin'],
    }),
    activityById: builder.query<IGenericResponse, number>({
      query: (id) => `${activityById}/${id}`,
      providesTags: ['admin'],
    }),
    addGroup: builder.mutation<void, Group>({
      query: (group) => ({
        url: createGroup,
        method: 'POST',
        body: group,
      }),
      invalidatesTags: ['admin'],
    }),
    addAlert: builder.mutation<void, IAlert>({
      query: (alert) => ({
        url: createAlert,
        method: 'POST',
        body: alert,
      }),
      invalidatesTags: ['admin'],
    }),
    addEducator: builder.mutation<IGenericResponse, Educator>({
      query: (educator) => {
        const formData = new FormData()
        formData.append('firstName', educator.firstName)
        formData.append('lastName', educator.lastName)
        formData.append('email', educator.email)
        formData.append('password', educator.password)
        if (educator.image) {
          formData.append('image', educator.image[0])
        }
        return {
          url: addEducator,
          method: 'POST',
          body: formData,
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data')
            return headers
          },
        }
      },
      invalidatesTags: ['admin'],
    }),
    deleteParent: builder.mutation<void, IUser>({
      query: ({ id }) => ({
        url: `${deleteParent}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin'],
    }),
    deleteActivity: builder.mutation<void, Activity>({
      query: ({ id }) => ({
        url: `${deleteActivity}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin'],
    }),
    deleteChild: builder.mutation<void, Child>({
      query: ({ id }) => ({
        url: `${deleteChild}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin'],
    }),
    deleteEducator: builder.mutation<void, Educator>({
      query: ({ id }) => ({
        url: `${deleteEducator}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin'],
    }),
    deleteGroup: builder.mutation<void, Group>({
      query: ({ id }) => ({
        url: `${deleteGroup}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin'],
    }),
    deleteAlert: builder.mutation<void, IAlert>({
      query: ({ id }) => ({
        url: `${deleteAlert}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admin'],
    }),
    registerChild: builder.mutation<IGenericResponse, Child>({
      query: ({ idParent, ...data }) => {
        const formData = new FormData()
        formData.append('firstName', data.firstName)
        formData.append('lastName', data.lastName)
        formData.append('age', String(data.age))
        if (data.allergy) {
          formData.append('allergy', String(data.allergy))
        }
        if (data.disease) {
          formData.append('disease', String(data.disease))
        }

        if (data.image) {
          formData.append('image', data.image[0])
        }
        return {
          url: `${registerChild}/${idParent}`,
          method: 'POST',
          body: formData,
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data')
            return headers
          },
        }
      },
      invalidatesTags: ['admin'],
    }),
    updateChild: builder.mutation<IGenericResponse, Child>({
      query: ({ id, ...data }) => {
        const formData = new FormData()
        formData.append('firstName', data.firstName)
        formData.append('lastName', data.lastName)
        formData.append('age', String(data.age))
        if (data.allergy) {
          formData.append('allergy', String(data.allergy))
        }
        if (data.disease) {
          formData.append('disease', String(data.disease))
        }

        if (data.image) {
          formData.append('image', data.image[0])
        }
        return {
          url: `${updateChild}/${id}`,
          method: 'PATCH',
          body: data,
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data')
            return headers
          },
        }
      },
      invalidatesTags: ['admin'],
    }),
    updateAlert: builder.mutation<IGenericResponse, IAlert>({
      query: ({ id, ...rest }) => ({
        url: `${updateAlert}/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['admin'],
    }),
    assignEducator: builder.mutation<void, IAssignEducator>({
      query: ({ groupId, educatorId }) => ({
        url: `groups/${groupId}/assign-educator/${educatorId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['admin'],
    }),
    assignChild: builder.mutation<void, IAssignChild>({
      query: ({ groupId, childId }) => ({
        url: `groups/${groupId}/assign-child/${childId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['admin'],
    }),
    filterdActivities: builder.query<IGenericResponse, SearchParams>({
      query: (params) => `${filteredActivities}?query=${params}`,
      providesTags: ['admin'],
    }),
  }),
})

export const {
  useAllParentsQuery,
  useRegisterChildMutation,
  useUpdateChildMutation,
  useDeleteParentMutation,
  useAllChildrenQuery,
  useAllEducatorsQuery,
  useAllActivitiesQuery,
  useDeleteChildMutation,
  useDeleteEducatorMutation,
  useDeleteActivityMutation,
  useAllGroupsQuery,
  useDeleteGroupMutation,
  useAddEducatorMutation,
  useAddGroupMutation,
  useAssignChildMutation,
  useAssignEducatorMutation,
  useTotalChildrenQuery,
  useTotalEducatorsQuery,
  useTotalParentsQuery,
  useAddAlertMutation,
  useUpdateAlertMutation,
  useDeleteAlertMutation,
  useAllAlertsQuery,
  useFilterdActivitiesQuery,
} = adminApi
