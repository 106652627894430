import React from 'react'
import { Grid } from '@mui/material'
import SideBar from '../../../Components/DashbordComponents/SideBar'
import { Outlet } from 'react-router-dom'

const HomeAdmin = () => {
  return (
    <Grid container xs={12}>
      <Grid item xs={1}>
        <SideBar />
      </Grid>
      <Grid item xs={11}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default HomeAdmin
