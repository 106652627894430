import React from 'react'
import {
  useAllChildrenQuery,
  useDeleteChildMutation,
} from '../../Redux/Api/adminApi'
import {
  DeleteButton,
  CustomDeleteIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { Child } from '../../Types/types'
import { useTranslation } from 'react-i18next'

interface ButtonProps {
  child: Child
}

export const DeleteChildButton: React.FC<ButtonProps> = ({ child }) => {
  const { t } = useTranslation()
  const [deleteChild] = useDeleteChildMutation()
  const { refetch } = useAllChildrenQuery()
  return (
    <DeleteButton
      variant="outlined"
      startIcon={<CustomDeleteIcon />}
      onClick={() => {
        deleteChild(child)
        refetch()
      }}
    >
      {t('deleteChild')}
    </DeleteButton>
  )
}
