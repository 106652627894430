import React from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import AboutUs from './Pages/AboutUs'
import Contact from './Pages/Contact'
import Pricing from './Pages/Pricing'
import Services from './Pages/Services'
import SignIn from './Pages/SignIn'
import SignUp from './Pages/SignUp'
import Header from './Components/Header/Header'
import Home from './Pages/Home'
import ForgetPassword from './Pages/ForgetPassword'
import ResetPassword from './Pages/ResetPassword'
import OneTimePasswordPasswordForm from './Features/Auth/ForgetPasswordForm/oneTimePasswordForm'
import HomeAdmin from './Pages/DashbordPages/Home/HomeAdmin'
import ParentHomePage from './Pages/ParentPages/ParentHomePage/ParentHomePage'
import { routes } from './Config/routes.enum'
import { useAppSelector } from './Redux/Store/store'
import { ParentsPage } from './Pages/DashbordPages/Parents/ParentsPage'
import { ChildrenPage } from './Pages/DashbordPages/Children/ChildrenPage'
import { EducatorsPage } from './Pages/DashbordPages/Educators/EducatorsPage'
import { GroupsPage } from './Pages/DashbordPages/Groups/GroupsPage'
import { ActivitiesPage } from './Pages/DashbordPages/Activities/ActivitiesPage'
import { FeedBackPage } from './Pages/DashbordPages/FeedBack/FeedBackPage'
import HomeComponent from './Components/DashbordComponents/HomeComponent'
import ChildrenActivities from './Pages/ParentPages/ChildrenActivitiesPage/ChildrenActivities'
import MyChildrenPage from './Pages/ParentPages/ChildrenPage/ChildrenPge'
import AlertsPage from './Pages/ParentPages/AlertsPage/AlertsPage'
import { EducatorHomePage } from './Pages/EducatorPages/EducatorHomePage'
import EducatorSetting from './Components/EducatorComponent/EducatorSettings'
function App() {
  const user = useAppSelector((state) => state.userSlice.user)
  const educator = useAppSelector((state) => state.userSlice.educator)

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path={routes.homePageRoute} element={<Home />} />
        <Route path={routes.aboutUsPageRoute} element={<AboutUs />} />
        <Route path={routes.contactPAgeRoute} element={<Contact />} />
        <Route path={routes.pricingPageRoute} element={<Pricing />} />
        <Route path={routes.sevicesPageRoute} element={<Services />} />
        <Route path={routes.loginPageRoute} element={<SignIn />} />
        <Route path={routes.signupPageRoute} element={<SignUp />} />
        <Route
          path={routes.forgetPasswordPageRoute}
          element={<ForgetPassword />}
        />
        <Route
          path={routes.resetPasswordPageRoute}
          element={<ResetPassword />}
        />
        <Route
          path={routes.oneTimePasswordPageRoute}
          element={<OneTimePasswordPasswordForm />}
        />
        <Route path={routes.parentHomePage} element={<ParentHomePage />} />
        <Route path={routes.parentChildrenPage} element={<MyChildrenPage />} />
        <Route
          path={`${routes.parentChildActivities}/:id`}
          element={<ChildrenActivities />}
        />
        <Route path={routes.parentAlerts} element={<AlertsPage />} />
        <Route path={routes.educatorHomePage} element={<EducatorHomePage />} />
        <Route
          path={routes.educatorSettingPage}
          element={<EducatorSetting />}
        />

        <Route path={routes.dashbordAdmin} element={<HomeAdmin />}>
          <Route path={routes.homePageContent} element={<HomeComponent />} />
          <Route path={routes.parentsPage} element={<ParentsPage />} />
          <Route path={routes.childrenPage} element={<ChildrenPage />} />
          <Route path={routes.educatorsPage} element={<EducatorsPage />} />
          <Route path={routes.groupsPage} element={<GroupsPage />} />
          <Route path={routes.activitiesPage} element={<ActivitiesPage />} />
          <Route path={routes.alertsPage} element={<AlertsPage />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
