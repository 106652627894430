import React, { useState, useEffect } from 'react'
import FileInput from '../../../Components/Inputs/FileInputs/FileInputs'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Educator } from '../../../Types/types'
import { Alert } from '@mui/material'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import PasswordInput from '../../../Components/Inputs/PasswordInput/PasswordInput'
import { addEducatorFormConfig } from './AddEducator.constants'
import {
  AddButton,
  AddModal,
  AddModalBox,
  CustomAddIcon,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAllEducatorsQuery,
  useAddEducatorMutation,
} from '../../../Redux/Api/adminApi'

interface IAddEducatorProps {
  onCloseModelHandler: () => void
}

export const AddEducatorForm: React.FC<IAddEducatorProps> = ({
  onCloseModelHandler,
}) => {
  const [addEducator, { isSuccess, isError }] = useAddEducatorMutation()
  const { refetch } = useAllEducatorsQuery()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Educator>()

  const onSubmitHandler: SubmitHandler<Educator> = (educator) => {
    addEducator(educator)
  }

  useEffect(() => {
    if (isSuccess) {
      onCloseModelHandler()
      setTimeout(() => {
        navigate(`${routes.dashbordAdmin}/${routes.educatorsPage}`)
      }, 1000)
      refetch()
    }
  }, [isSuccess, navigate, refetch, onCloseModelHandler])
  return (
    <AddModal open onClose={onCloseModelHandler}>
      <AddModalBox onSubmit={handleSubmit(onSubmitHandler)} component="form">
        <TextInput
          label="firstName"
          options={{
            ...register('firstName', {
              required: {
                value: true,
                message: t(addEducatorFormConfig.firstName.required),
              },
              minLength: {
                value: addEducatorFormConfig.firstName.minLength.value,
                message: t(addEducatorFormConfig.firstName.minLength.message),
              },
            }),
          }}
        />
        {errors.firstName && (
          <Alert severity="error">{errors.firstName.message}</Alert>
        )}
        <TextInput
          label="lastName"
          options={{
            ...register('lastName', {
              required: {
                value: true,
                message: t(addEducatorFormConfig.lastName.required),
              },
              minLength: {
                value: addEducatorFormConfig.lastName.minLength.value,
                message: t(addEducatorFormConfig.lastName.minLength.message),
              },
            }),
          }}
        />
        {errors.lastName && (
          <Alert severity="error">{errors.lastName.message}</Alert>
        )}
        <TextInput
          label="email"
          options={{
            ...register('email', {
              required: {
                value: true,
                message: t(addEducatorFormConfig.email.required),
              },
              minLength: {
                value: addEducatorFormConfig.email.minLength.value,
                message: t(addEducatorFormConfig.email.minLength.message),
              },
            }),
          }}
        />
        {errors.email && <Alert severity="error">{errors.email.message}</Alert>}
        <PasswordInput
          label="password"
          options={{
            ...register('password', {
              required: {
                value: true,
                message: t(addEducatorFormConfig.password.required),
              },
              minLength: {
                value: addEducatorFormConfig.password.minLength.value,
                message: t(addEducatorFormConfig.password.minLength.message),
              },
              maxLength: {
                value: addEducatorFormConfig.password.maxLength.value,
                message: t(addEducatorFormConfig.password.maxLength.message),
              },
            }),
          }}
        />
        {errors.password && (
          <Alert severity="error">{errors.password.message}</Alert>
        )}
        <FileInput
          label="Add Image Profile"
          options={{ ...register('image') }}
        />
        <AddButton
          size="large"
          type="submit"
          variant="outlined"
          startIcon={<CustomAddIcon />}
        >
          {t('add Educator')}
        </AddButton>
      </AddModalBox>
    </AddModal>
  )
}
