import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import translationEn from '../Locales/en/translation.json'
import translationFr from '../Locales/fr/translation.json'

const resources = {
  en: { translation: translationEn },
  fr: { translation: translationFr },
}
i18next.use(initReactI18next).use(I18nextBrowserLanguageDetector).init({
  fallbackLng: 'en',
  debug: true,
  resources,
})
