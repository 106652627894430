import React from 'react'
import { styled } from '@mui/system'
import {
  Card,
  CardHeader,
  Typography,
  CardMedia,
  CardContent,
  Grid,
} from '@mui/material'

export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

export const StyledHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: '#E5E5E5',
  borderBottom: '1px solid #D8D8D8',
  paddingTop: '1rem',
  paddingBottom: '1rem',
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
}))

export const StyledMedia = styled(CardMedia)(({ theme }) => ({
  height: 100,
  paddingTop: '56.25%',
}))
export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flex: '1 0 auto',
}))

export const CenteredGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  margin: '55px auto 0',
  maxWidth: 1200,
  '@media (max-width: 1280px)': {
    maxWidth: 960,
  },
  '@media (max-width: 960px)': {
    maxWidth: 720,
  },
  '@media (max-width: 600px)': {
    maxWidth: 450,
  },
}))
