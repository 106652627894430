import React, { useState } from 'react'
import Pagination from '../Shared/Pagination/Pagination'
import {
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Paper,
  TextField,
  Grid,
} from '@mui/material'
import {
  ActivitiesListGrid,
  ActivitiesListGridItem,
  ActivitiesListBox,
  ActivitiesListGridItemCard,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { Activity, SearchParams } from '../../Types/types'
import {
  useAllActivitiesQuery,
  useFilterdActivitiesQuery,
} from '../../Redux/Api/adminApi'
import { imageUrl } from '../../Redux/Api/paths'
import { ActivityDeleteButton } from './ActivityDeleteButton'
import { ActivityInfoButton } from './ActvityInfoIcon'
import { ActityModalCOmponent } from './ActityModalCOmponent'
import { useTranslation } from 'react-i18next'
import { CenteredGrid } from '../Shared/MuiStyledComponent/ParentMuiComponents/ParentMuiComponents'
export const ActivitiesList: React.FC = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const [searchQuery, setSearchQuery] = useState<SearchParams>({})
  const {
    data: activities,
    isError,
    isFetching,
    isSuccess,
  } = useAllActivitiesQuery(page)
  const {
    data: filteredActivities,
    isSuccess: filteredIsSuccess,
    isError: filteredIsError,
    isFetching: filteredIsFetching,
  } = useFilterdActivitiesQuery(searchQuery)

  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null
  )
  const [openModal, setOpenModal] = useState(false)

  const handleActivityModalClose = () => {
    setSelectedActivity(null)
  }

  const handleActivityClick = (activity: Activity) => {
    setSelectedActivity(activity)
  }
  //TOFIX : IS NOT WORKING
  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }
  console.log('hello', activities)
  console.log(filteredActivities)
  return (
    <>
      <CenteredGrid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Search by title, description, Date"
            name="title"
            variant="outlined"
            onChange={handleSearchQueryChange}
            sx={{ ml: 'auto', mr: 2, my: 1, width: '40ch' }}
          />
        </Grid>
        {isFetching && <p>{t('fetchingData')}</p>}
        {isError && <p>{t('error')}</p>}
        {activities?.data.data &&
          activities.data.data?.map((activity: Activity, index: number) => (
            <ActivitiesListGridItem key={index} item xs={10} sm={10} md={4}>
              <ActivitiesListGridItemCard>
                <Paper elevation={10}>
                  {activity.images && (
                    <CardMedia
                      component="img"
                      image={imageUrl + activity.images[0]}
                      alt={activity.title}
                    />
                  )}
                  <CardContent sx={{ marginLeft: 'auto' }}>
                    <Typography variant="h5" component="h2" sx={{ mb: 1 }}>
                      {activity.title + ' : ' + activity.group?.name}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {'educator : ' + activity.group?.educator?.firstName}
                    </Typography>
                  </CardContent>
                  <ActivitiesListBox>
                    <IconButton>
                      <ActivityDeleteButton activity={activity} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleActivityClick(activity)
                      }}
                    >
                      <ActivityInfoButton />
                    </IconButton>
                  </ActivitiesListBox>
                </Paper>
              </ActivitiesListGridItemCard>
            </ActivitiesListGridItem>
          ))}

        <Pagination
          onPageChange={setPage}
          currentPage={page}
          totalPages={activities?.data.last_page}
        />

        {activities?.data.data.length === 0 && t('No Activities for now')}
      </CenteredGrid>
      {selectedActivity && (
        <ActityModalCOmponent
          selectedActivity={selectedActivity}
          onCloseModal={handleActivityModalClose}
        />
      )}
    </>
  )
}
