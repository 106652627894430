import React, { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemButton,
} from '@mui/material'
import { AddGroupForm } from '../../Features/Dashbord/AddGroup/AddGroupForm'
import { AddEducatorForm } from '../../Features/Dashbord/AddEducator/AddEducatorForm'
import { AddActivity } from '../../Features/Educator/AddActivity'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import CastForEducationIcon from '@mui/icons-material/CastForEducation'
import CommentIcon from '@mui/icons-material/Comment'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import { roles } from '../../Config/roles.enum'
import MenuIcon from '@mui/icons-material/Menu'
import LoginIcon from '@mui/icons-material/Login'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import InfoIcon from '@mui/icons-material/Info'
import AddIcon from '@mui/icons-material/Add'
import { SettingsEthernet } from '@mui/icons-material'
import { useAppSelector, useAppDispatch } from '../../Redux/Store/store'
import { logOut } from '../../Redux/Redux-Slices/userSlice'
import { routes } from '../../Config/routes.enum'
import {
  DrawerStyledComponent,
  DrawerMenuIcon,
} from '../Shared/MuiStyledComponent/DrawerStyledComponents'

export const DrawerComponent = () => {
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [openEducatorModal, setOpenEducatorModal] = useState<boolean>(false)
  const [addActivityModal, setAddActivityModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.userSlice.user)
  const educator = useAppSelector((state) => state.userSlice.educator)
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const adminPages = [
    {
      text: 'Parents',
      icon: <SupervisorAccountIcon />,
      link: `${routes.dashbordAdmin}/${routes.parentsPage}`,
    },
    {
      text: 'Parents',
      icon: <SupervisorAccountIcon />,
      link: `${routes.dashbordAdmin}/${routes.parentsPage}`,
    },
    {
      text: 'Children',
      icon: <BabyChangingStationIcon />,
      link: `${routes.dashbordAdmin}/${routes.childrenPage}`,
    },
    {
      text: 'Educators',
      icon: <CastForEducationIcon />,
      link: `${routes.dashbordAdmin}/${routes.educatorsPage}`,
    },
    {
      text: 'Groups',
      icon: <Diversity3Icon />,
      link: `${routes.dashbordAdmin}/${routes.groupsPage}`,
    },
    {
      text: 'Activities',
      icon: <SquareFootOutlinedIcon />,
      link: `${routes.dashbordAdmin}/${routes.activitiesPage}`,
    },
    {
      text: 'Alerts',
      icon: <AddAlertIcon />,
      link: `${routes.dashbordAdmin}/${routes.alertsPage}`,
    },
  ]
  const ParentIcons = [
    { text: 'Settings', icon: <SettingsEthernet />, link: {} },
    {
      text: 'Children',
      icon: <BabyChangingStationIcon />,
      link: routes.parentChildrenPage,
    },
    {
      text: 'Alerts',
      icon: <SquareFootOutlinedIcon />,
      link: routes.parentAlerts,
    },
  ]

  const pages = [
    {
      name: 'About us',
      link: `${routes.aboutUsPageRoute}`,
      component: <InfoIcon />,
    },
    {
      name: 'Services',
      link: `${routes.sevicesPageRoute}`,
      component: <DesignServicesIcon />,
    },
    {
      name: 'Contact',
      link: `${routes.contactPAgeRoute}`,
      component: <ContactMailIcon />,
    },
    {
      name: 'Pricing',
      link: `${routes.pricingPageRoute}`,
      component: <AttachMoneyIcon />,
    },
    {
      name: 'Signup',
      link: `${routes.signupPageRoute}`,
      component: <CardMembershipIcon />,
    },
    {
      name: 'Login',
      link: `${routes.loginPageRoute}`,
      component: <LoginIcon />,
    },
  ]
  const logOutHandler = () => {
    dispatch(logOut())
    navigate('/login')
  }
  const onCloseDrawerHandler = (): void => {
    setOpenDrawer(false)
  }
  const drawerHandler = () => {
    setOpenDrawer((prevState) => !prevState)
  }
  const openGroupModalHandler = () => {
    setOpenGroupModal(true)
  }
  const closeGroupModalHandler = () => {
    setOpenGroupModal(false)
  }
  const openEducatorModalHandler = () => {
    setOpenEducatorModal(true)
  }
  const closeEducatorModalHandler = () => {
    setOpenEducatorModal(false)
  }
  const openAddActivityModalHandler = () => {
    setAddActivityModal(true)
    onCloseDrawerHandler()
  }
  const closeAddActivityModalHandler = () => {
    setAddActivityModal(false)
  }

  return (
    <Fragment>
      <DrawerStyledComponent open={openDrawer} onClose={onCloseDrawerHandler}>
        <List>
          {!user &&
            !educator &&
            pages.map((page, index) => (
              <ListItem
                component={Link}
                to={page.link}
                key={index}
                onClick={onCloseDrawerHandler}
              >
                <ListItemButton>
                  <ListItemIcon>{page.component}</ListItemIcon>
                  <ListItemText>{page.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          {user?.roles === roles.admin ? (
            <>
              {adminPages.map((page, index) => (
                <ListItem
                  component={Link}
                  to={page.link}
                  key={index}
                  onClick={onCloseDrawerHandler}
                >
                  <ListItemButton>
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText>{page.text}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem>
                <ListItemButton onClick={logOutHandler}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Log out</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={openEducatorModalHandler}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText>Add Educator</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={openGroupModalHandler}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText>Add Group</ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          ) : null}
          {user?.roles === roles.parent ? (
            <>
              {ParentIcons.map((page, index) => (
                <ListItem
                  component={Link}
                  to={page.link}
                  key={index}
                  onClick={onCloseDrawerHandler}
                >
                  <ListItemButton>
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText>{page.text}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem>
                <ListItemButton onClick={logOutHandler}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Log out</ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          ) : null}
          {educator?.roles === roles.educator ? (
            <>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Add Activity</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={openAddActivityModalHandler}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText>Add Activity</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    navigate(routes.educatorSettingPage)
                  }}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText>Setting</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={logOutHandler}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Log out</ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          ) : null}
        </List>
      </DrawerStyledComponent>
      <DrawerMenuIcon onClick={drawerHandler}>
        <MenuIcon />
      </DrawerMenuIcon>
      {openGroupModal && (
        <AddGroupForm
          onOpenModalHandler={openGroupModalHandler}
          onCloseModalHandler={closeGroupModalHandler}
        />
      )}
      {openEducatorModal && (
        <AddEducatorForm onCloseModelHandler={closeEducatorModalHandler} />
      )}
      {addActivityModal && (
        <AddActivity onCloseModelHandler={closeAddActivityModalHandler} />
      )}
    </Fragment>
  )
}
