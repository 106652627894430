import React from 'react'
import {
  useTotalChildrenQuery,
  useTotalEducatorsQuery,
  useTotalParentsQuery,
} from '../../Redux/Api/adminApi'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Divider,
  Paper,
} from '@mui/material'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import {
  CardWrapper,
  NumberDisplay,
  HomeComponentGridRoot,
  HomeComponentGridContainer,
  HomeComponentGridItem,
  ChartGrid,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'

const data = [
  { date: '2022-01-01', visitors: 100 },
  { date: '2022-01-02', visitors: 200 },
  { date: '2022-01-03', visitors: 300 },
]
export default function HomeComponent() {
  const { data: totalChildren } = useTotalChildrenQuery()
  const { data: totalParents } = useTotalParentsQuery()
  const { data: totalEducators } = useTotalEducatorsQuery()
  return (
    <>
      <HomeComponentGridRoot>
        <HomeComponentGridContainer container>
          <HomeComponentGridItem item xs={12} sm={12} md={4}>
            <CardWrapper>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Parents
                </Typography>
                <NumberDisplay>
                  {totalParents ? <span>{totalParents.data}</span> : 'None'}
                </NumberDisplay>
              </CardContent>
            </CardWrapper>
          </HomeComponentGridItem>

          <HomeComponentGridItem item xs={12} sm={12} md={4}>
            <CardWrapper sx={{ bgcolor: '#E0E0E0' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Children
                </Typography>
                <NumberDisplay>
                  {' '}
                  {totalChildren ? <span>{totalChildren.data}</span> : 'None'}
                </NumberDisplay>
              </CardContent>
            </CardWrapper>
          </HomeComponentGridItem>
          <HomeComponentGridItem item xs={12} sm={12} md={4}>
            {' '}
            <CardWrapper sx={{ bgcolor: '#BDBDBD' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Educators
                </Typography>
                <NumberDisplay>
                  {totalEducators ? totalEducators.data : 'None'}
                </NumberDisplay>
              </CardContent>
            </CardWrapper>
          </HomeComponentGridItem>
        </HomeComponentGridContainer>
        <ChartGrid item xs={12} sm={12} md={5}>
          <Paper sx={{ width: '650px' }}>
            {' '}
            <Box>
              <Typography>Visitors Traffic</Typography>
            </Box>
            <ResponsiveContainer width="90%" height={400}>
              <LineChart width={600} height={400} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="visitors" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </ChartGrid>
      </HomeComponentGridRoot>

      <Divider />
    </>
  )
}
