import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../Store/store'
import {
  Activity,
  ChildActivitiesParameter,
  IGenericResponse,
  ListResponse,
} from '../../Types/types'
import {
  backendPath,
  myChildren,
  myChildActivities,
  parentAlert,
} from './paths'

export const parentApi = createApi({
  reducerPath: 'parentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backendPath,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['parent'],
  endpoints: (builder) => ({
    myChildren: builder.query<IGenericResponse, number>({
      query: (id) => `${myChildren}/${id}`,
      providesTags: ['parent'],
    }),
    myChildActivities: builder.query<
      ListResponse<Activity>,
      ChildActivitiesParameter
    >({
      query: ({ childId, page }) =>
        `${myChildActivities}/${childId}?page=${page}`,
      providesTags: ['parent'],
    }),
    myAlerts: builder.query<IGenericResponse, number>({
      query: (id) => `${parentAlert}/${id}`,
      providesTags: ['parent'],
    }),
  }),
})

export const {
  useMyChildActivitiesQuery,
  useMyChildrenQuery,
  useMyAlertsQuery,
} = parentApi
