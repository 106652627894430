import {
  Alert,
  Checkbox,
  Paper,
  Grid,
  Avatar,
  Typography,
  FormControlLabel,
} from '@mui/material'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import LoginIcon from '@mui/icons-material/Login'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import PasswordInput from '../../../Components/Inputs/PasswordInput/PasswordInput'
import {
  useLoginUserMutation,
  useLoginEducatorMutation,
} from '../../../Redux/Api/authApi'
import { ICredentials } from '../../../Types/types'
import { LoginFormConfig } from './LoginForm.constants'
import SubmitButton from '../../../Components/Shared/SubmitButton/SubmitButton'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../Redux/Store/store'
import { roles } from '../../../Config/roles.enum'
export const LoginForm = () => {
  const user = useAppSelector((state) => state.userSlice.user)
  const educator = useAppSelector((state) => state.userSlice.educator)
  const isParent = useAppSelector((state) => state.userSlice.isParent)
  const isAdmin = useAppSelector((state) => state.userSlice.isAdmin)
  const isEducator = useAppSelector((state) => state.userSlice.isEducator)
  const { t } = useTranslation()
  const [
    loginUser,
    { data: parent, isError: parentError, isSuccess: parentSuccess },
  ] = useLoginUserMutation()
  const [
    loginEducator,
    { data: educatorData, isError: educatorError, isSuccess: educatorSuccess },
  ] = useLoginEducatorMutation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICredentials>()
  const onSubmit: SubmitHandler<ICredentials> = async (inputData) => {
    if (isParent || isAdmin) {
      await loginUser(inputData)
    } else if (isEducator) {
      await loginEducator(inputData)
    }
  }
  useEffect(() => {
    if (parentSuccess && user?.roles === roles.parent) {
      setTimeout(() => {
        navigate(`${routes.parentHomePage}`)
      }, 2000)
    } else if (parentSuccess && user?.roles === roles.admin) {
      setTimeout(() => {
        navigate(`${routes.dashbordAdmin}/${routes.homePageContent}`)
      }, 2000)
    } else if (educatorSuccess && educator) {
      setTimeout(() => {
        navigate(`${routes.educatorHomePage}`)
      }, 1000)
    } else {
      return
    }
  }, [parentSuccess, educatorSuccess, navigate, user, educator])

  const avatarStyle = { backgroundColor: '#82a886' }
  return (
    <Paper
      elevation={0}
      sx={{ padding: 10, height: '100%' }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginY={5}
      >
        <Avatar style={avatarStyle}>
          <LoginIcon />
        </Avatar>
        <Typography variant="h5">Sign In</Typography>
      </Grid>

      <TextInput
        label="Email"
        options={{
          ...register('email', {
            required: {
              value: true,
              message: t(LoginFormConfig.email.required),
            },
            minLength: {
              value: LoginFormConfig.email.minLength.value,
              message: t(LoginFormConfig.email.minLength.message),
            },
          }),
        }}
      />
      {errors.email && <Alert severity="error">{errors.email.message}</Alert>}
      <PasswordInput
        label="Password"
        options={{
          ...register('password', {
            required: {
              value: true,
              message: t(LoginFormConfig.password.required),
            },
            minLength: {
              value: LoginFormConfig.password.minLength.value,
              message: t(LoginFormConfig.password.minLength.message),
            },
            maxLength: {
              value: LoginFormConfig.password.maxLength.value,
              message: t(LoginFormConfig.password.maxLength.message),
            },
          }),
        }}
      />
      {errors.password && (
        <Alert severity="error">{errors.password.message}</Alert>
      )}
      <FormControlLabel
        control={<Checkbox name="remember" aria-label="Remember me" />}
        label="Remember me"
      />
      {parentSuccess && <Alert severity="success">{parent?.message}</Alert>}
      {educatorSuccess && (
        <Alert severity="success">{educatorData?.message}</Alert>
      )}
      {parentError && <Alert severity="error">{t('invalidCredentials')}</Alert>}
      {educatorError && (
        <Alert severity="error">{t('invalidCredentials')}</Alert>
      )}
      <SubmitButton text="Login" />
      <Grid
        container
        direction="column"
        justifyContent="center"
        marginY={5}
        marginX={5}
      >
        <Typography marginY={1}>
          <Link to="/forget-password">{t('forgetPassword')}</Link>
        </Typography>
        <Typography>
          {t('haveAccoutn')}
          <Link to="/signup">Sign Up </Link>
        </Typography>
      </Grid>
    </Paper>
  )
}
