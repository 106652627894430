import React from 'react'
import {
  useDeleteAlertMutation,
  useAllAlertsQuery,
} from '../../Redux/Api/adminApi'
import { IAlert } from '../../Types/types'
import { useTranslation } from 'react-i18next'
import {
  DeleteButton,
  CustomDeleteIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'

interface ButtonProps {
  alert: IAlert
}

export const AlertDeleteButton: React.FC<ButtonProps> = ({ alert }) => {
  const { t } = useTranslation()
  const [deleteAlert] = useDeleteAlertMutation()
  const { refetch } = useAllAlertsQuery()
  return (
    <DeleteButton
      variant="outlined"
      startIcon={<CustomDeleteIcon />}
      onClick={() => {
        deleteAlert(alert)
        refetch()
      }}
    >
      {t('Delete')}
    </DeleteButton>
  )
}
