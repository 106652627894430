import { useState } from 'react'
import {
  Grid,
  Typography,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from '@mui/material'
import { useAllChildrenQuery } from '../../Redux/Api/adminApi'
import { Child } from '../../Types/types'

import { DeleteChildButton } from './DeleteChildButtonComponent'
import { imageUrl } from '../../Redux/Api/paths'
import { useTranslation } from 'react-i18next'
import {
  TableGridComponent,
  TableContainerComponent,
  CustomAvatar,
  StyledPaginationButton,
  PaginationBox,
  AddButton,
  CustomAddIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import Pagination from '../Shared/Pagination/Pagination'
import { UpdateChild } from '../../Features/Dashbord/UpdateChild/UpdateChild'

export const Children = () => {
  const [openUpdateChildModel, setOpenUpdateChildModel] =
    useState<boolean>(false)
  const [selectedChild, setSelectedChild] = useState<Child | undefined>(
    undefined
  )
  const [page, setPage] = useState(1)
  const {
    data: children,
    isSuccess,
    isError,
    isFetching,
    isLoading,
  } = useAllChildrenQuery(page)
  const { t } = useTranslation()
  const openUpdateChildModelHandler = (child: Child) => {
    setOpenUpdateChildModel(true)
    setSelectedChild(child)
  }
  const closeUpdateChildModelHandler = () => {
    setOpenUpdateChildModel(false)
  }

  return (
    <TableGridComponent container>
      <Grid item xs={10} sm={10} md={10}>
        {' '}
        {isError && <p>{t('error')}</p>}
        {isFetching && <p>{t('fetchingData')}</p>}
        <Paper>
          <TableContainerComponent>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>IMage</TableCell>
                  <TableCell>FirstNAme</TableCell>
                  <TableCell>LastName</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>Allergy</TableCell>
                  <TableCell>Disease</TableCell>
                  <TableCell>Parent</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess && Array.isArray(children.data.data) ? (
                  children.data.data.map((child: Child) => (
                    <TableRow key={child.id}>
                      <TableCell>{child.id}</TableCell>
                      <TableCell>
                        {child.image && (
                          <CustomAvatar
                            src={imageUrl + child.image}
                            alt="child"
                          />
                        )}
                      </TableCell>
                      <TableCell>{child.firstName}</TableCell>
                      <TableCell>{child.lastName}</TableCell>
                      <TableCell>{child.age}</TableCell>
                      <TableCell>{child.allergy}</TableCell>
                      <TableCell>{child.disease}</TableCell>
                      <TableCell>
                        {child.parent &&
                          child.parent?.firstName + child.parent?.lastName}
                      </TableCell>
                      <TableCell>
                        {child.group ? child.group?.name : 'None'}
                      </TableCell>
                      <TableCell>
                        <DeleteChildButton child={child} />
                      </TableCell>
                      <TableCell>
                        <AddButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="outlined"
                          startIcon={<CustomAddIcon />}
                          onClick={() => {
                            openUpdateChildModelHandler(child)
                          }}
                        >
                          {t('Update Child')}
                        </AddButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography>{t('No Children')}</Typography>
                )}
              </TableBody>
            </Table>
          </TableContainerComponent>
          <Pagination
            onPageChange={setPage}
            currentPage={page}
            totalPages={children?.data.last_page}
          />
        </Paper>
      </Grid>
      {openUpdateChildModel && (
        <UpdateChild
          onCloseModelHandler={closeUpdateChildModelHandler}
          child={selectedChild}
        />
      )}
    </TableGridComponent>
  )
}
