import { useState } from 'react'
import {
  Grid,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useAllGroupsQuery } from '../../Redux/Api/adminApi'
import { Group, Child } from '../../Types/types'
import { DeleteGroupButton } from './DeleteGroupButtonComponent'
import { useTranslation } from 'react-i18next'
import {
  AddButton,
  CustomAddIcon,
  TableGridComponent,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { AssignEducatorForm } from '../../Features/Dashbord/AssignEducator/AssignEducatorForm'
import { AssignChildForm } from '../../Features/Dashbord/AssignChild/AssingChild'
import { DisplayChildrenPerGroup } from '../../Features/Dashbord/DisplayChildrenPerGroup/DisplayChildrenPerGroup'
export const Groups = () => {
  const { t } = useTranslation()
  const { data, isSuccess, isError, isFetching } = useAllGroupsQuery()
  const [childrenGroup, setChildrenGroup] = useState<Child[] | undefined>(
    undefined
  )
  const [openEducatorModal, setOpenEducatorModal] = useState<boolean>(false)
  const [openChildrenModal, setOpenChildrenModal] = useState<boolean>(false)
  const [openChildrenGroupModal, setOpenChildrenGroupModal] =
    useState<boolean>(false)
  const [groupId, setGroupId] = useState<number | undefined>(undefined)
  const closeEducatorModalHandler = () => {
    setOpenEducatorModal(false)
  }
  const openEducatorModalHandler = (group: Group) => {
    setOpenEducatorModal(true)
    setGroupId(group.id)
  }
  const closeChildrenModalHandler = () => {
    setOpenChildrenModal(false)
  }
  const openChildrenModalHandler = (group: Group) => {
    setOpenChildrenModal(true)
    setGroupId(group.id)
  }
  const openChildrenGroupModalHandler = (group: Group) => {
    setOpenChildrenGroupModal(true)
    setChildrenGroup(group.children)
  }
  const closeChildrenGroupModalHandler = () => {
    setOpenChildrenGroupModal(false)
  }
  return (
    <TableGridComponent container>
      <Grid item xs={10} sm={12} md={10}>
        {isError && <p>{t('error')}</p>}
        {isFetching && <p>{t('fetchingData')}</p>}
        <TableContainer component={Paper} sx={{ marginTop: '35px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Group ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Educator</TableCell>
                <TableCell>Delete</TableCell>
                <TableCell>Assign Educator</TableCell>
                <TableCell>Assign Child</TableCell>
                <TableCell>Children</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess && Array.isArray(data.data) ? (
                data.data.map((group: Group) => (
                  <TableRow key={group.id}>
                    <TableCell>{group.id}</TableCell>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>
                      {group.educator
                        ? group.educator.firstName +
                          ' ' +
                          group.educator.lastName
                        : 'None'}
                    </TableCell>
                    <TableCell>
                      <DeleteGroupButton group={group} />
                    </TableCell>
                    <TableCell>
                      <AddButton
                        size="large"
                        type="submit"
                        variant="outlined"
                        startIcon={<CustomAddIcon />}
                        onClick={() => {
                          openEducatorModalHandler(group)
                        }}
                      >
                        {t('Assign Educator')}
                      </AddButton>
                    </TableCell>
                    <TableCell>
                      <AddButton
                        size="large"
                        type="submit"
                        variant="outlined"
                        startIcon={<CustomAddIcon />}
                        onClick={() => {
                          openChildrenModalHandler(group)
                        }}
                      >
                        {t('Assign Child')}
                      </AddButton>
                    </TableCell>
                    <TableCell>
                      <AddButton
                        size="large"
                        type="submit"
                        variant="outlined"
                        startIcon={<VisibilityIcon />}
                        onClick={() => {
                          openChildrenGroupModalHandler(group)
                        }}
                      >
                        {t('Children')}
                      </AddButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <p>{t('noGroups')}</p>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {openEducatorModal && (
          <AssignEducatorForm
            handleCloseModal={closeEducatorModalHandler}
            groupId={groupId}
          />
        )}
        {openChildrenModal && (
          <AssignChildForm
            handleCloseModal={closeChildrenModalHandler}
            groupId={groupId}
          />
        )}{' '}
        {openChildrenGroupModal && (
          <DisplayChildrenPerGroup
            handleCloseModal={closeChildrenGroupModalHandler}
            childrenPerGroup={childrenGroup}
          />
        )}
      </Grid>
    </TableGridComponent>
  )
}
