import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import Parents from '../../../Assets/Images/Parents.jpg'

export const AuthImgComponent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundImage: `url(${Parents})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'black',
  backgroundSize: 'cover',
  height: '90%',
  width: '50%',
  display: 'flex',
}))
