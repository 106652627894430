import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { imageUrl } from '../../../Redux/Api/paths'
import { Typography, Alert } from '@mui/material'
import {
  AddButton,
  CustomAddIcon,
  Avatar,
  StyledCheckbox,
  StyledModal,
  ListContainer,
  EducatorList,
  EducatorItem,
  TextAndAvatar,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAllEducatorsQuery,
  useAssignEducatorMutation,
  useAllGroupsQuery,
} from '../../../Redux/Api/adminApi'
import { Educator, IAssignEducator } from '../../../Types/types'

interface Props {
  handleCloseModal: () => void
  groupId: number | undefined
}

export const AssignEducatorForm: React.FC<Props> = ({
  handleCloseModal,
  groupId,
}) => {
  const { t } = useTranslation()
  const [selectedEducator, setSelectedEducator] = useState<Educator | null>(
    null
  )
  console.log(selectedEducator)
  const { data, isFetching } = useAllEducatorsQuery()
  const [assignEducator, { isSuccess, isError }] = useAssignEducatorMutation()
  const { refetch } = useAllGroupsQuery()

  const handleEducatorSelection = (educator: Educator) => {
    setSelectedEducator(educator)
  }
  const assingEducatorHandler = (data: IAssignEducator) => {
    assignEducator(data)
  }

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal()
      refetch()
    }
  }, [isSuccess, refetch, handleCloseModal])
  return (
    <StyledModal open onClose={handleCloseModal}>
      <ListContainer>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {t(' Select an educator')}
        </Typography>
        <EducatorList>
          {data?.data.map((educator: Educator) => (
            <EducatorItem
              key={educator.id}
              onClick={() => {
                handleEducatorSelection(educator)
              }}
            >
              <TextAndAvatar>
                {' '}
                <Avatar>
                  <img src={imageUrl + educator.image} alt="educator" />
                </Avatar>
                <Typography variant="h5" sx={{ marginRight: '25px' }}>
                  {educator.firstName + ' ' + educator.lastName}
                </Typography>
              </TextAndAvatar>

              <StyledCheckbox
                checked={selectedEducator?.id === educator.id}
                color="primary"
                disableRipple
              />
            </EducatorItem>
          ))}
          <AddButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            startIcon={<CustomAddIcon />}
            onClick={() => {
              assingEducatorHandler({
                groupId,
                educatorId: selectedEducator?.id,
              })
            }}
          >
            {t('Confirm')}
          </AddButton>
        </EducatorList>
        {isSuccess && (
          <Alert severity="success">
            {t('Educator assigned successfully')}
          </Alert>
        )}
        {isError && (
          <Alert severity="error">{t('Failed to assign Educator')}</Alert>
        )}
      </ListContainer>
    </StyledModal>
  )
}
