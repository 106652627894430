import React from 'react'
import { Box, Grid, Container } from '@mui/material'
import { styled } from '@mui/system'
import EducatorInfo from '../../Components/EducatorComponent/EducatorInfo'
import ChildrenList from '../../Components/EducatorComponent/EducatorChildren'
import ActivitiesList from '../../Components/EducatorComponent/EducatorActivities'

export const EducatorHomePage = () => {
  const StyledContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: 'none',
    marginTop: 80,
  })

  return (
    <StyledContainer>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <EducatorInfo />
        </Grid>
        <Grid item xs={12} md={12}>
          <ChildrenList />
        </Grid>
        <Grid item xs={12} md={12}>
          <ActivitiesList />
        </Grid>
      </Grid>
    </StyledContainer>
  )
}
