import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DrawerComponent } from '../Drawer/DrawerComponent'
import { routes } from '../../Config/routes.enum'
import { useAppDispatch, useAppSelector } from '../../Redux/Store/store'
import { logOut } from '../../Redux/Redux-Slices/userSlice'
import { roles } from '../../Config/roles.enum'
import { AddGroupForm } from '../../Features/Dashbord/AddGroup/AddGroupForm'
import { AddEducatorForm } from '../../Features/Dashbord/AddEducator/AddEducatorForm'
import { AddAlertForm } from '../../Features/Dashbord/AddAlert/AddAlertForm'
import LoginButton from '../Shared/LoginButton/LoginButton'
import {
  Toolbar,
  Tabs,
  Tab,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material'
import {
  AppBarComponent,
  TypographyComponent,
  SignUpButton,
  SignInButton,
} from '../Shared/MuiStyledComponent/HeaderStyledComponent'
import { SideBarButton } from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import { AddActivity } from '../../Features/Educator/AddActivity'
const Header = () => {
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [openEducatorModal, setOpenEducatorModal] = useState<boolean>(false)
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)
  const [openAddActivityModal, setOpenAddActivityModal] =
    useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.userSlice.user)
  const educator = useAppSelector((state) => state.userSlice.educator)
  const isEducator = useAppSelector((state) => state.userSlice.isEducator)
  const [value, setValue] = useState<number>()
  const navigate = useNavigate()
  const pages = [
    { name: 'About us', link: `${routes.aboutUsPageRoute}` },
    { name: 'Services', link: `${routes.sevicesPageRoute}` },
    { name: 'Contact', link: `${routes.contactPAgeRoute}` },
    { name: 'Pricing', link: `${routes.pricingPageRoute}` },
  ]
  const ParentIcons = [
    { text: 'Settings', icon: <SettingsEthernetIcon />, link: {} },
    {
      text: 'Children',
      icon: <BabyChangingStationIcon />,
      link: routes.parentChildrenPage,
    },
    {
      text: 'Alerts',
      icon: <SquareFootOutlinedIcon />,
      link: routes.parentAlerts,
    },
  ]
  const EducatorIcons = [
    {
      text: 'Settings',
      icon: <SettingsEthernetIcon />,
      link: routes.educatorSettingPage,
    },
    {
      text: 'My Group',
      icon: <BabyChangingStationIcon />,
      link: {},
    },
    {
      text: 'My Activities',
      icon: <BabyChangingStationIcon />,
      link: {},
    },
  ]
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  const changeLinkHandler = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setValue(value)
  }
  const logOutHnadler = async () => {
    dispatch(logOut())
    navigate(`${routes.loginPageRoute}`)
  }
  const openGroupModalHandler = () => {
    setOpenGroupModal(true)
  }
  const closeGroupModalHandler = () => {
    setOpenGroupModal(false)
  }
  const openEducatorModalHandler = () => {
    setOpenEducatorModal(true)
  }
  const closeEducatorModalHandler = () => {
    setOpenEducatorModal(false)
  }
  const openAlertModalHandler = () => {
    setOpenAlertModal(true)
  }
  const closeAlertModalHandler = () => {
    setOpenAlertModal(false)
  }
  const openAddActivitylHandler = () => {
    setOpenAddActivityModal(true)
  }
  const closeAddActivityModalHandler = () => {
    setOpenAddActivityModal(false)
  }
  return (
    <React.Fragment>
      <AppBarComponent position="fixed">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            startIcon={<FamilyRestroomIcon />}
            onClick={() => {
              navigate(`${routes.homePageRoute}`)
            }}
          >
            <TypographyComponent>{t('appName')}</TypographyComponent>
          </Button>

          {isMatch && <DrawerComponent />}
          {!isMatch && !user && !educator && (
            <>
              <Tabs
                sx={{ marginLeft: 'auto' }}
                textColor="inherit"
                onChange={changeLinkHandler}
                value={value}
              >
                {pages.map((page, index) => (
                  <Tab
                    label={page.name}
                    key={index}
                    component={NavLink}
                    to={page.link}
                  />
                ))}
              </Tabs>
              <Box component={NavLink} to={routes.signupPageRoute}>
                <SignUpButton>Sign up</SignUpButton>
              </Box>
              <LoginButton />
            </>
          )}
          {!isMatch && user?.roles === roles.parent && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 'auto',
                }}
              >
                {ParentIcons.map((parent, index) => (
                  <Tab
                    label={parent.text}
                    key={index}
                    component={NavLink}
                    to={parent.link}
                  />
                ))}
              </Box>

              <SignUpButton onClick={logOutHnadler}>
                {t('Log out')}{' '}
              </SignUpButton>
            </>
          )}
          {!isMatch && educator?.roles === roles.educator && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SideBarButton onClick={openAddActivitylHandler}>
                  {t(' Add Activity ')}
                </SideBarButton>
                <SideBarButton
                  onClick={() => {
                    navigate(routes.educatorSettingPage)
                  }}
                >
                  {t(' Settings ')}
                </SideBarButton>
                <SignUpButton onClick={logOutHnadler}>
                  {t('Log out')}{' '}
                </SignUpButton>
              </Box>
            </>
          )}
          {!isMatch && user?.roles === roles.admin && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SideBarButton onClick={openGroupModalHandler}>
                  {t('Add Group')}
                </SideBarButton>
                <SideBarButton onClick={openEducatorModalHandler}>
                  {t(' Add Educator')}
                </SideBarButton>
                <SideBarButton onClick={openAlertModalHandler}>
                  {t(' Send Alert')}
                </SideBarButton>
                <SideBarButton onClick={logOutHnadler}>
                  {t('Logout')}
                </SideBarButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBarComponent>
      {openGroupModal && (
        <AddGroupForm
          onOpenModalHandler={openGroupModalHandler}
          onCloseModalHandler={closeGroupModalHandler}
        />
      )}
      {openEducatorModal && (
        <AddEducatorForm onCloseModelHandler={closeEducatorModalHandler} />
      )}
      {openAlertModal && (
        <AddAlertForm
          onOpenModalHandler={openAlertModalHandler}
          onCloseModalHandler={closeAlertModalHandler}
        />
      )}
      {openAddActivityModal && (
        <AddActivity onCloseModelHandler={closeAddActivityModalHandler} />
      )}
    </React.Fragment>
  )
}

export default Header
