import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import { ForgetPasswordFormConfig } from './ForgetPassword.types'
import { Paper, Grid, Typography, Alert } from '@mui/material'
import SubmitButton from '../../../Components/Shared/SubmitButton/SubmitButton'
import { useForgetPasswordMutation } from '../../../Redux/Api/authApi'
import { IEmailInput } from '../../../Types/types'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'

const ForgetPasswordForm = () => {
  const { t } = useTranslation()

  const [forgetPassword, { data, isSuccess, error }] =
    useForgetPasswordMutation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEmailInput>()

  const onSubmit: SubmitHandler<IEmailInput> = async (inputData) => {
    await forgetPassword(inputData)
  }
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate(`${routes.resetPasswordPageRoute}`)
      }, 3000)
    }
  })
  return (
    <Paper
      elevation={10}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ padding: 10 }}
    >
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          Enter your Email to get your OTP
        </Typography>
      </Grid>
      <TextInput
        label={t('email')}
        options={{
          ...register('email', {
            required: {
              value: true,
              message: t(ForgetPasswordFormConfig.email.required),
            },
            minLength: {
              value: ForgetPasswordFormConfig.email.minLength.value,
              message: t(ForgetPasswordFormConfig.email.minLength.required),
            },
          }),
        }}
      />
      {errors.email && <Alert severity="error">{errors.email.message}</Alert>}
      {isSuccess && <Alert severity="success">{data?.message}</Alert>}
      {error && <Alert severity="error">{t('invalidMail')}</Alert>}
      <SubmitButton text="Email verification" />
    </Paper>
  )
}

export default ForgetPasswordForm
