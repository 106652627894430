export const backendPath = 'http://localhost:80/api'
export const imageUrl = 'http://localhost:80/storage/'
export const loginPath = '/users/login'
export const loginEducatorPath = '/educator/login'
export const logOutPath = '/users/logout'
export const signUpPath = '/users/register'
export const forgetPasswordPath = '/password/email'
export const oneTimePasswordPath = '/password/code/check'
export const resetPasswordPath = '/password/reset'
export const registerChild = '/children/register'
export const allChildren = '/children/all'
export const child = '/children/'
export const updateChild = '/children/update'
export const deleteChild = '/children/delete'
export const restore = '/children/restore'
export const forceDelete = '/children/forceDelete'
export const allParents = '/parents/all'
export const allEducators = '/educators/all'
export const addEducator = '/educators/register'
export const deleteParent = '/parents/delete'
export const deleteEducator = '/educators/delete'
export const createGroup = '/groups/create'
export const groupById = '/groups'
export const assignChildToGroup = ''
export const assignEducatorToGroup = ''
export const allGroups = '/groups/all'
export const childrenByGroup = ''
export const groupActivities = ''
export const deleteGroup = 'groups/delete'
export const allActivities = 'activity/all'
export const activityById = 'activity'
export const deleteActivity = 'activity/delete'
export const totalChildren = 'parents/totalChildren'
export const totalEducators = 'educators/total'
export const totalParents = 'parents/total'
export const createAlert = '/alert/create'
export const getAllAlerts = '/alert/all'
export const updateAlert = '/alert/update'
export const deleteAlert = '/alert/delete'
export const myChildren = 'parents/myChildren'
export const myChildActivities = 'parents/child/activities'
export const parentAlert = '/alert'
export const educatorProfile = 'educator/profile'
export const updateEducator = 'educator/update'
export const createActivity = 'activity/create'
export const getAllChildrenGroup = 'educator/children'
export const educatorActivities = 'activity/group'
export const filteredActivities = 'activity/filtered'
