export const AddAlertFormConfig = {
  title: {
    required: 'AddAlertFormConfig.title.required',
    minLength: {
      value: 4,
      message: 'AddAlertFormConfig.title.minLength.message',
    },
  },
  message: {
    required: 'AddAlertFormConfig.message.required',
    minLength: {
      value: 4,
      message: 'AddAlertFormConfig.message.minLength.message',
    },
  },
  start_date: {
    required: 'AddAlertFormConfig.start_date.required',
  },
  end_date: {
    required: 'AddAlertFormConfig.end_date.required',
  },
  send_to: {
    required: 'AddAlertFormConfig.send_to.required',
  },
}
