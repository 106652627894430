import React, { useState } from 'react'
import {
  useMediaQuery,
  useTheme,
  List,
  Toolbar,
  Divider,
  ListItemText,
  ListItemButton,
} from '@mui/material'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import CastForEducationIcon from '@mui/icons-material/CastForEducation'
import CommentIcon from '@mui/icons-material/Comment'
import LogoutIcon from '@mui/icons-material/Logout'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined'
import { useAppDispatch, useAppSelector } from '../../Redux/Store/store'
import { logOut } from '../../Redux/Redux-Slices/userSlice'
import { useNavigate, Link } from 'react-router-dom'
import { routes } from '../../Config/routes.enum'
import { AddGroupForm } from '../../Features/Dashbord/AddGroup/AddGroupForm'
import {
  SideBarListItem,
  SideBarListItemButton,
  SideBarListItemIcon,
  SideBarListItemText,
  StyledDrawer,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { AddEducatorForm } from '../../Features/Dashbord/AddEducator/AddEducatorForm'
import { roles } from '../../Config/roles.enum'

const Icons = [
  {
    text: 'Parents',
    icon: <SupervisorAccountIcon />,
    link: routes.parentsPage,
  },
  {
    text: 'Children',
    icon: <BabyChangingStationIcon />,
    link: routes.childrenPage,
  },
  {
    text: 'Educators',
    icon: <CastForEducationIcon />,
    link: routes.educatorsPage,
  },
  {
    text: 'Groups',
    icon: <Diversity3Icon />,
    link: routes.groupsPage,
  },
  {
    text: 'Activities',
    icon: <SquareFootOutlinedIcon />,
    link: routes.activitiesPage,
  },
  { text: 'Alerts', icon: <AddAlertIcon />, link: routes.alertsPage },
]

const AdminIcons = [
  { text: 'Settings', icon: <SettingsEthernetIcon />, link: {} },
  { text: 'Logout', icon: <LogoutIcon /> },
]

export default function SideBar() {
  const user = useAppSelector((state) => state.userSlice.user)
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [openEducatorModal, setOpenEducatorModal] = useState<boolean>(false)
  const theme = useTheme()
  const isScreenMedium = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const logOutHandler = () => {
    dispatch(logOut())
    navigate(`${routes.loginPageRoute}`)
  }

  const openGroupModalHandler = () => {
    setOpenGroupModal(true)
  }
  const closeGroupModalHandler = () => {
    setOpenGroupModal(false)
  }
  const closeEducatorModalHandler = () => {
    setOpenEducatorModal(false)
  }
  return (
    <>
      {isScreenMedium && (
        <StyledDrawer variant="permanent">
          <Toolbar />
          <Divider />
          <List sx={{ margntTop: '40px' }}>
            {user?.roles === roles.admin &&
              Icons.map((text, index) => (
                <SideBarListItem key={text.text} disablePadding>
                  <ListItemButton component={Link} to={text.link}>
                    <SideBarListItemIcon>{text.icon}</SideBarListItemIcon>
                    <SideBarListItemText primary={text.text} />
                  </ListItemButton>
                </SideBarListItem>
              ))}
          </List>
          <Divider />
          <List>
            {user?.roles === roles.admin &&
              AdminIcons.map((text, index) => (
                <SideBarListItem key={text.text} disablePadding>
                  <SideBarListItemButton
                    onClick={index === 1 ? logOutHandler : undefined}
                  >
                    <SideBarListItemIcon>{text.icon}</SideBarListItemIcon>
                    <SideBarListItemText primary={text.text} />
                  </SideBarListItemButton>
                </SideBarListItem>
              ))}
            <Divider />
          </List>
        </StyledDrawer>
      )}

      {openGroupModal && (
        <AddGroupForm
          onOpenModalHandler={openGroupModalHandler}
          onCloseModalHandler={closeGroupModalHandler}
        />
      )}
      {openEducatorModal && (
        <AddEducatorForm onCloseModelHandler={closeEducatorModalHandler} />
      )}
    </>
  )
}
