import React from 'react'
import { useParams } from 'react-router-dom'
import { ChildActivities } from '../../../Components/ParentComponents/ChildActivities'
import { Grid } from '@mui/material'
import { CenteredGrid } from '../../../Components/Shared/MuiStyledComponent/ParentMuiComponents/ParentMuiComponents'
export default function ChildrenActivities() {
  const { id } = useParams()
  const parsedId = +id!

  return (
    <CenteredGrid container spacing={2}>
      <ChildActivities childId={parsedId} />
    </CenteredGrid>
  )
}
