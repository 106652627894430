import React, { useState } from 'react'
import {
  PaginationBox,
  StyledPaginationButton,
} from '../MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { useTranslation } from 'react-i18next'

interface PaginationProps {
  onPageChange: (pageNumber: number) => void
  currentPage: number
  totalPages: number | undefined
}

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  currentPage,
  totalPages,
}) => {
  const { t } = useTranslation()
  const [prevDisabled, setPrevDisabled] = useState(currentPage === 1)
  const [nextDisabled, setNextDisabled] = useState(currentPage === totalPages)

  const handlePrevClick = () => {
    const newPage = currentPage - 1
    onPageChange(newPage)
    setPrevDisabled(newPage === 1)
    setNextDisabled(newPage === totalPages)
  }

  const handleNextClick = () => {
    const newPage = currentPage + 1
    onPageChange(newPage)
    setPrevDisabled(newPage === 1)
    setNextDisabled(newPage === totalPages)
  }

  return (
    <PaginationBox>
      <StyledPaginationButton onClick={handlePrevClick} disabled={prevDisabled}>
        {t('Previous')}
      </StyledPaginationButton>
      <StyledPaginationButton onClick={handleNextClick} disabled={nextDisabled}>
        {t('Next')}
      </StyledPaginationButton>
    </PaginationBox>
  )
}

export default Pagination
