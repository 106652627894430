import React from 'react'
import ResetPasswordForm from '../Features/Auth/RestPasswordForm/ResetPasswordForm'
import { Paper, useTheme, useMediaQuery, Grid } from '@mui/material'
import { ForgetPasswordFormGrid } from '../Components/Shared/MuiStyledComponent/ForgetPasswordFormGrid'
const ResetPassword = () => {
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  React.useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])
  const paperStyle = {
    width: isMatch ? '80%' : 450,
    marginX: 10,
    borderRadius: 10,
  }
  return (
    <ForgetPasswordFormGrid>
      <Paper style={paperStyle}>
        <ResetPasswordForm />
      </Paper>
    </ForgetPasswordFormGrid>
  )
}

export default ResetPassword
