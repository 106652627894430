import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { IPasswordInput } from '../../../Types/types'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import { Paper, Grid, Alert } from '@mui/material'
import { ResetPasswordFormConfig } from './ResetPassword.types'
import SubmitButton from '../../../Components/Shared/SubmitButton/SubmitButton'
import { useResetPasswordPathMutation } from '../../../Redux/Api/authApi'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPasswordInput>()
  const [ResetPassword, { data, isSuccess, isError }] =
    useResetPasswordPathMutation()
  const onSubmit: SubmitHandler<IPasswordInput> = async (inputData) => {
    console.log(inputData)
    await ResetPassword(inputData)
  }
  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate(`${routes.loginPageRoute}`)
      }, 2500)
    }
  }, [isSuccess, navigate])
  return (
    <>
      <Paper
        elevation={10}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ padding: 10 }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <TextInput
              label="one time password"
              options={{
                ...register('code', {
                  required: {
                    value: true,
                    message: t(ResetPasswordFormConfig.code.required),
                  },
                  minLength: {
                    value: ResetPasswordFormConfig.code.minLength.value,
                    message: t(ResetPasswordFormConfig.code.minLength.message),
                  },
                  maxLength: {
                    value: ResetPasswordFormConfig.code.maxLength.value,
                    message: t(ResetPasswordFormConfig.code.maxLength.message),
                  },
                }),
              }}
            />
            {errors.code && (
              <Alert severity="error">{errors.code.message}</Alert>
            )}
            <TextInput
              label=" Reset Password"
              options={{
                ...register('password', ResetPasswordFormConfig.password),
              }}
            />
            {isError && <Alert severity="error">{t('invalidOTP')}</Alert>}
            {errors.password && (
              <Alert severity="error">{errors.password.message}</Alert>
            )}
            {isSuccess && <Alert severity="success">{data?.message}</Alert>}

            <SubmitButton text="Reset Password" />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ResetPasswordForm
