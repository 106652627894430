import React from 'react'
import {
  useDeleteParentMutation,
  useAllParentsQuery,
} from '../../Redux/Api/adminApi'
import {
  DeleteButton,
  CustomDeleteIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { IUser } from '../../Types/types'
import { useTranslation } from 'react-i18next'

interface ButtonProps {
  user: IUser
}

export const DeleteButtonComponent: React.FC<ButtonProps> = ({ user }) => {
  const [deleteParent] = useDeleteParentMutation()
  const { refetch } = useAllParentsQuery()
  const { t } = useTranslation()
  return (
    <DeleteButton
      variant="outlined"
      startIcon={<CustomDeleteIcon />}
      onClick={() => {
        deleteParent(user)
        refetch()
      }}
    >
      {t('deleteParent')}
    </DeleteButton>
  )
}
