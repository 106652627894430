export const OneTimePasswordFormConfig = {
  code: {
    required: 'OneTimePasswordForm.code.required',
    minLength: {
      value: 4,
      required: 'OneTimePasswordForm.code.minLength.required',
    },
    maxLength: {
      value: 4,
      required: 'OneTimePasswordForm.code.maxLength.required',
    },
  },
}
