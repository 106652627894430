import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { Child, IRegisterChild, IRegisterForm } from '../../../Types/types'
import { Alert, Button } from '@mui/material'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import FileInput from '../../../Components/Inputs/FileInputs/FileInputs'
import { AddChildFormConfig } from './AddChild.constants'
import {
  AddButton,
  AddModal,
  AddModalBox,
  CustomAddIcon,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAllChildrenQuery,
  useRegisterChildMutation,
} from '../../../Redux/Api/adminApi'
import NumberInput from '../../../Components/Inputs/TextInputComponent/NumberInput'

interface IAddChildProps {
  onCloseModelHandler: () => void
  idParent: number | undefined
}

export const AddChildForm: React.FC<IAddChildProps> = ({
  onCloseModelHandler,
  idParent,
}) => {
  const [registerChild, { isSuccess, isError }] = useRegisterChildMutation()
  const { refetch } = useAllChildrenQuery()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Child>()

  const onSubmitHandler: SubmitHandler<Child> = (child) => {
    registerChild({ idParent, ...child })
  }

  useEffect(() => {
    if (isSuccess) {
      onCloseModelHandler()
      setTimeout(() => {
        navigate(`${routes.dashbordAdmin}/${routes.childrenPage}`, {
          replace: true,
        })
      }, 2000)
      refetch()
    }
  }, [isSuccess, navigate, refetch, onCloseModelHandler])
  return (
    <AddModal open onClose={onCloseModelHandler}>
      <AddModalBox onSubmit={handleSubmit(onSubmitHandler)} component="form">
        <TextInput
          label="firstName"
          options={{
            ...register('firstName', {
              required: {
                value: true,
                message: t(AddChildFormConfig.firstName.required),
              },
              minLength: {
                value: AddChildFormConfig.firstName.minLength.value,
                message: t(AddChildFormConfig.firstName.minLength.message),
              },
            }),
          }}
        />
        {errors.firstName && (
          <Alert severity="error">{errors.firstName.message}</Alert>
        )}
        <TextInput
          label="lastName"
          options={{
            ...register('lastName', {
              required: {
                value: true,
                message: t(AddChildFormConfig.lastName.required),
              },
              minLength: {
                value: AddChildFormConfig.lastName.minLength.value,
                message: t(AddChildFormConfig.lastName.minLength.message),
              },
            }),
          }}
        />
        {errors.lastName && (
          <Alert severity="error">{errors.lastName.message}</Alert>
        )}

        <NumberInput
          label="age"
          options={{
            ...register('age', {
              required: {
                value: true,
                message: t(AddChildFormConfig.age.required),
              },
              min: {
                value: AddChildFormConfig.age.min.value,
                message: t(AddChildFormConfig.age.min.message),
              },
              max: {
                value: AddChildFormConfig.age.max.value,
                message: t(AddChildFormConfig.age.max.message),
              },
            }),
          }}
        />
        {errors.age && <Alert severity="error">{errors.age.message}</Alert>}
        <TextInput
          label="Allergy"
          options={{
            ...register('allergy'),
          }}
        />
        {errors.allergy && (
          <Alert severity="error">{errors.allergy.message}</Alert>
        )}
        <TextInput
          label="Disease"
          options={{
            ...register('disease'),
          }}
        />
        {errors.disease && (
          <Alert severity="error">{errors.disease.message}</Alert>
        )}
        <FileInput
          label="Add Image Profile"
          options={{ ...register('image') }}
        />
        {errors.image && <Alert severity="error">{errors.image.message}</Alert>}
        <AddButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          startIcon={<CustomAddIcon />}
        >
          {t('add Child')}
        </AddButton>
      </AddModalBox>
    </AddModal>
  )
}
