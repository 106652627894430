import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'
import { UseFormRegister, UseFormRegisterReturn } from 'react-hook-form'
import { IPasswordInputProps } from './PasswordInput.type'

function PasswordInput({ label, options }: IPasswordInputProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <TextField
      sx={{ marginY: 2 }}
      variant="standard"
      fullWidth
      label={label}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {!showPassword && <VisibilityOffIcon />}
              {showPassword && <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...options}
    />
  )
}

export default PasswordInput
