import React from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/system'

export const ForgetPasswordFormGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  backgroundColor: '#CAE1CE',
  justifyContent: 'center',
  alignItems: 'center',
}))
