import React from 'react'
import { Box, AppBar, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'

export const AppBarComponent = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#82a886',
  zIndex: 1201,
}))

export const TypographyComponent = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  marginLeft: '1%',
  color: '#e6e8eb',
}))

export const SignUpButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  color: '#e6e8eb',
}))

export const SignInButton = styled(Button)(({ theme }) => ({
  marginLeft: '10px',
  color: '#e6e8eb',
}))
