import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import { OneTimePasswordFormConfig } from './oneTimePassword.type'
import { Paper, Grid, Typography, Alert } from '@mui/material'
import SubmitButton from '../../../Components/Shared/SubmitButton/SubmitButton'
import { useOneTimePasswordPathMutation } from '../../../Redux/Api/authApi'
import { ICodeInput } from '../../../Types/types'
import { routes } from '../../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
const OneTimePasswordPasswordForm = () => {
  const { t } = useTranslation()
  const [oneTimePassword, { data, isSuccess, error }] =
    useOneTimePasswordPathMutation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICodeInput>()

  const onSubmit: SubmitHandler<ICodeInput> = async (inputData) => {
    await oneTimePassword(inputData)
  }
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate(`${routes.resetPasswordPageRoute}`)
      }, 3000)
    }
  })
  return (
    <Paper
      elevation={10}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ padding: 10 }}
    >
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          Enter your One Time Password
        </Typography>
      </Grid>
      <TextInput
        label="one time password"
        options={{
          ...register('code', {
            required: OneTimePasswordFormConfig.code.required,
            minLength: {
              value: OneTimePasswordFormConfig.code.minLength.value,
              message: t(OneTimePasswordFormConfig.code.minLength.required),
            },
            maxLength: {
              value: OneTimePasswordFormConfig.code.maxLength.value,
              message: t(OneTimePasswordFormConfig.code.maxLength.required),
            },
          }),
        }}
      />
      {errors.code && <Alert severity="error">{errors.code.message}</Alert>}
      {isSuccess && <Alert severity="success">{data?.message}</Alert>}
      {error && <Alert severity="error">{t('invalidOTP')}</Alert>}
      <SubmitButton text="Code check" />
    </Paper>
  )
}

export default OneTimePasswordPasswordForm
