import React, { useState } from 'react'
import {
  Box,
  Grid,
  Avatar,
  Typography,
  TextField,
  Button,
  Divider,
  Rating,
  Stack,
  Alert,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { imageUrl } from '../../Redux/Api/paths'
import { Educator } from '../../Types/types'
import { useUpdateEducatorMutation } from '../../Redux/Api/educatorApi'
import { useAppSelector, useAppDispatch } from '../../Redux/Store/store'
import { updateEducatorState } from '../../Redux/Redux-Slices/userSlice'

const StyledGrid = styled(Grid)({
  maxWidth: 800,
  margin: 'auto',
  padding: 16,
})

const StyledAvatar = styled(Avatar)({
  width: 120,
  height: 120,
})

const StyledTypography = styled(Typography)({
  marginTop: 16,
})

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    backgroundColor: '#f8f8f8',
    borderRadius: 4,
    padding: '8px 12px',
    fontSize: 16,
    fontWeight: 500,
  },
  '& .MuiInputLabel-root': {
    fontSize: 16,
    fontWeight: 500,
  },
})

const StyledButton = styled(Button)({
  marginTop: 16,
  padding: '8px 32px',
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: '#82a886',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#82a886',
  },
})

const EducatorSetting: React.FC = () => {
  const educator = useAppSelector((state) => state.userSlice.educator)
  const globalState = useAppSelector((state) => state.userSlice)
  const dispatch = useAppDispatch()
  const [educatorFirstName, setEducatorFirstName] = useState(
    educator?.firstName ?? ''
  )
  const [educatorLastName, setEducatorLastName] = useState(
    educator?.lastName ?? ''
  )
  const [educatorEmail, setEducatorEmail] = useState(educator?.email ?? '')
  const [educatorPassword, setEducatorPassword] = useState(
    educator?.password ?? ''
  )
  const [educatorEducation, setEducatorEducation] = useState(
    educator?.education ?? ''
  )
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [updateEducator, { data, isSuccess, isError }] =
    useUpdateEducatorMutation()
  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEducatorFirstName(event.target.value)
  }
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEducatorLastName(event.target.value)
  }
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEducatorEmail(event.target.value)
  }
  const handleEducationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEducatorEducation(event.target.value)
  }
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEducatorPassword(event.target.value)
  }
  //THE API IS WORKING , BUT THE STATE IS NOT UPDATED , WHEN I CONSOLE.LOG(EDUCATOR IS UNDEFINED )
  const handleSave = () => {
    const updatedEducator: Educator = {
      ...educator,
      firstName: educatorFirstName,
      lastName: educatorLastName,
      email: educatorEmail,
      education: educatorEducation,
      password: educatorPassword,
    }
    const newState = {
      ...globalState,
      educator: updatedEducator,
    }

    updateEducator(updatedEducator)
    if (isSuccess) {
      dispatch(updateEducatorState(newState))
    }
  }
  console.log(educator)
  return (
    <Box sx={{ flexGrow: 1, marginTop: 10 }}>
      <StyledGrid container spacing={2}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <StyledAvatar
            alt={`${educator?.firstName} ${educator?.lastName}`}
            src={imageUrl + educator?.image}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              maxWidth: '750px',
            }}
          >
            <StyledTypography variant="h4">{`${educatorFirstName} ${educatorLastName}`}</StyledTypography>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {educator?.education}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <Rating value={4.5} readOnly precision={0.5} />
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                ({educator?.reviews})
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={2}>
              <StyledTextField
                label="First Name"
                defaultValue={educatorFirstName}
                onChange={handleFirstNameChange}
                fullWidth
              />
              <StyledTextField
                label="Last Name"
                defaultValue={educatorLastName}
                onChange={handleLastNameChange}
                fullWidth
              />
              <StyledTextField
                label="Email"
                defaultValue={educatorEmail}
                onChange={handleEmailChange}
                fullWidth
              />
              <StyledTextField
                label="Password"
                defaultValue={educatorPassword}
                onChange={handlePasswordChange}
                type="password"
                fullWidth
              />
              <StyledTextField
                label="Education Degree"
                defaultValue={educatorEducation}
                onChange={handleEducationChange}
                fullWidth
              />
            </Stack>
            <StyledButton variant="contained" onClick={handleSave}>
              Save Changes
            </StyledButton>
            {isSuccess && (
              <Alert severity="success"> Infos updated successfully </Alert>
            )}
            {isError && (
              <Alert severity="error">Something wrong happened</Alert>
            )}
          </Box>
        </Grid>
      </StyledGrid>
    </Box>
  )
}

export default EducatorSetting
