import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../Config/routes.enum'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { Activity, IActivityInputFields } from '../../Types/types'
import { Alert, Button, List, ListItem } from '@mui/material'
import TextInput from '../../Components/Inputs/TextInputComponent/TextInput'
import TextArea from '../../Components/Inputs/TextAreaInputComponent/TextAreaInputComponent'
import FileInput from '../../Components/Inputs/FileInputs/FileInputs'
import { useAppSelector } from '../../Redux/Store/store'

import { AddActivityFormConfig } from './AddActivity.constants'
import {
  AddButton,
  AddModal,
  AddModalBox,
  CustomAddIcon,
} from '../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { useCreateActivityMutation } from '../../Redux/Api/educatorApi'

interface IAddActivitydProps {
  onCloseModelHandler: () => void
}

export const AddActivity: React.FC<IAddActivitydProps> = ({
  onCloseModelHandler,
}) => {
  const educatorGroupName = useAppSelector(
    (state) => state.userSlice.educator?.group?.name
  )
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [createActivity, { isSuccess, isError }] = useCreateActivityMutation()
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IActivityInputFields>()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files != null) {
      const newFiles = Array.from(files)
      setSelectedFiles((prevSelected) => [...prevSelected, ...newFiles])
    }
  }

  const onSubmitHandler: SubmitHandler<IActivityInputFields> = (
    activity: IActivityInputFields
  ) => {
    const formData = new FormData()
    formData.append('title', activity.title)
    formData.append('description', activity.description)
    formData.append('groupName', educatorGroupName!)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('images[]', selectedFiles[i])
    }

    createActivity(formData)
  }

  useEffect(() => {
    if (isSuccess) {
      onCloseModelHandler()
      setTimeout(() => {
        navigate(routes.educatorHomePage)
      }, 1000)
    }
  }, [isSuccess, navigate, onCloseModelHandler])

  return (
    <AddModal open onClose={onCloseModelHandler}>
      <AddModalBox onSubmit={handleSubmit(onSubmitHandler)} component="form">
        <TextInput
          label="title"
          options={{
            ...register('title', {
              required: {
                value: true,
                message: t(AddActivityFormConfig.title.required),
              },
              minLength: {
                value: AddActivityFormConfig.title.minLength.value,
                message: t(AddActivityFormConfig.title.minLength.message),
              },
            }),
          }}
        />
        {errors.title && <Alert severity="error">{errors.title.message}</Alert>}
        <TextArea
          label="description"
          options={{
            ...register('description', {
              required: {
                value: true,
                message: t(AddActivityFormConfig.description.required),
              },
              minLength: {
                value: AddActivityFormConfig.description.minLength.value,
                message: t(AddActivityFormConfig.description.minLength.message),
              },
            }),
          }}
        />
        {errors.description && (
          <Alert severity="error">{errors.description.message}</Alert>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          {...register('images')}
          onChange={handleFileChange}
          ref={inputRef}
        />
        <Button
          sx={{
            marginTop: '10px',
            bgcolor: '#82a886',
            color: 'white',
            '&:hover': {
              bgcolor: '#82a886',
            },
          }}
          onClick={() => inputRef.current?.click()}
        >
          Select Files
        </Button>
        {errors.images && (
          <Alert severity="error">{errors.images.message}</Alert>
        )}
        {selectedFiles && (
          <List sx={{ mt: 2 }}>
            {Array.from(selectedFiles).map((file, i) => (
              <ListItem key={i} sx={{ py: 1 }}>
                {file.name}
              </ListItem>
            ))}
          </List>
        )}
        <AddButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          startIcon={<CustomAddIcon />}
        >
          {t('add Activity')}
        </AddButton>
      </AddModalBox>
    </AddModal>
  )
}
