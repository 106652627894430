import React, { useState } from 'react'
import Pagination from '../Shared/Pagination/Pagination'
import { AddButton } from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { CustomAddIcon } from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { IAlert } from '../../Types/types'
import { AlertDeleteButton } from './AlertDeleteButton'
import { CenteredGrid } from '../Shared/MuiStyledComponent/ParentMuiComponents/ParentMuiComponents'
import { Box, Typography, Paper, Grid, Alert, Button } from '@mui/material'
import { useAllAlertsQuery } from '../../Redux/Api/adminApi'
import { UpdateAlert } from '../../Features/Dashbord/UpdateAlert/UpdateAlert'
import { useTranslation } from 'react-i18next'

export default function AlertList() {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const {
    data: alerts,
    isSuccess,
    isError,
    isFetching,
  } = useAllAlertsQuery(page)
  const [openUpdateAlertModel, setOpenUpdateAlertModel] =
    useState<boolean>(false)
  const [selectedAlert, setSelectedAlert] = useState<IAlert | undefined>(
    undefined
  )
  const openUpdateAlertModelHandler = (alert: IAlert) => {
    setOpenUpdateAlertModel(true)
    setSelectedAlert(alert)
  }
  const closeUpdateAlertModelHandler = () => {
    setOpenUpdateAlertModel(false)
  }
  return (
    <CenteredGrid container spacing={2}>
      {alerts?.data.data &&
        alerts?.data.data.map((alert: IAlert) => (
          <Grid item xs={12} sm={6} key={alert.id}>
            {' '}
            <Paper
              elevation={3}
              sx={{
                marginLeft: '-25px',
                marginRight: '25px',
                mt: 1,
                mb: 2,
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 1,
                }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ marginBottom: '10px' }}
                >
                  {alert.title}
                </Typography>
              </Box>
              <Alert severity="info" sx={{ marginBottom: '15px' }}>
                {alert.message}
              </Alert>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 1,
                }}
              >
                <Typography variant="subtitle1" color="text.secondary">
                  {`Start Date: ${new Date(
                    alert.start_date
                  ).toLocaleDateString()}`}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {`End Date: ${new Date(alert.end_date).toLocaleDateString()}`}
                </Typography>
                <AlertDeleteButton alert={alert} />
                <Button
                  sx={{
                    fontSize: '10px',
                    color: '#82a886',
                    width: '20%',
                    height: '5%',
                    marginLeft: '10px',
                    outlineColor: '#82a886',
                    borderColor: '#82a886',
                    '&:hover': {
                      backgroundColor: '#82a886',
                      color: 'white',
                      borderColor: '#82a886',
                    },
                  }}
                  type="submit"
                  variant="outlined"
                  startIcon={<CustomAddIcon />}
                  onClick={() => {
                    openUpdateAlertModelHandler(alert)
                  }}
                >
                  {t('Update')}
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      <Pagination
        onPageChange={setPage}
        currentPage={page}
        totalPages={alerts?.data.last_page}
      />
      {openUpdateAlertModel && (
        <UpdateAlert
          alert={selectedAlert}
          onCloseModelHandler={closeUpdateAlertModelHandler}
        />
      )}
    </CenteredGrid>
  )
}
