import React, { useState } from 'react'
import Pagination from '../Shared/Pagination/Pagination'
import {
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Paper,
} from '@mui/material'
import {
  ActivitiesListGrid,
  ActivitiesListGridItem,
  ActivitiesListBox,
  ActivitiesListGridItemCard,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { Activity } from '../../Types/types'
import { useMyChildActivitiesQuery } from '../../Redux/Api/parentApi'
import { imageUrl } from '../../Redux/Api/paths'
import { ActivityInfoButton } from '../DashbordComponents/ActvityInfoIcon'
import { ActityModalCOmponent } from '../DashbordComponents/ActityModalCOmponent'
import { useTranslation } from 'react-i18next'

interface IProps {
  childId: number
}

export const ChildActivities: React.FC<IProps> = ({ childId }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const {
    data: activities,
    isError,
    isFetching,
    isSuccess,
  } = useMyChildActivitiesQuery({ childId, page })
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null
  )
  const [openModal, setOpenModal] = useState(false)

  const handleActivityModalClose = () => {
    setSelectedActivity(null)
  }

  const handleActivityClick = (activity: Activity) => {
    setSelectedActivity(activity)
  }
  return (
    <>
      <ActivitiesListGrid container spacing={2}>
        {isFetching && <p>{t('fetchingData')}</p>}
        {isError && <p>{t('error')}</p>}
        {activities?.data.data &&
          activities?.data.data.map((activity: Activity, index: number) => (
            <ActivitiesListGridItem key={index} item xs={10} sm={10} md={4}>
              <ActivitiesListGridItemCard>
                <Paper elevation={10}>
                  {activity.images && (
                    <CardMedia
                      component="img"
                      image={imageUrl + activity.images[0]}
                      alt={activity.title}
                    />
                  )}
                  <CardContent sx={{ marginLeft: 'auto' }}>
                    <Typography variant="h5" component="h2" sx={{ mb: 1 }}>
                      {activity.title + ' : ' + activity.group?.name}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {'educator : ' + activity.group?.educator?.firstName}
                    </Typography>
                  </CardContent>
                  <ActivitiesListBox>
                    <IconButton
                      onClick={() => {
                        handleActivityClick(activity)
                      }}
                    >
                      <ActivityInfoButton />
                    </IconButton>
                  </ActivitiesListBox>
                </Paper>
              </ActivitiesListGridItemCard>
            </ActivitiesListGridItem>
          ))}
        {selectedActivity && (
          <ActityModalCOmponent
            selectedActivity={selectedActivity}
            onCloseModal={handleActivityModalClose}
          />
        )}
      </ActivitiesListGrid>
      <Pagination
        onPageChange={setPage}
        currentPage={page}
        totalPages={activities?.data.last_page}
      />
    </>
  )
}
