import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@mui/material'
import { imageUrl } from '../../Redux/Api/paths'
import { styled } from '@mui/system'
import { Child } from '../../Types/types'

const StyledCard = styled(Card)({
  width: '100%',
})

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '56.25%',
})

type ChildCardProps = {
  child: Child
}

const ChildCard = ({ child }: ChildCardProps) => {
  const { image, firstName, lastName, age, group } = child
  return (
    <StyledCard>
      <StyledCardMedia
        image={imageUrl + image}
        title={`${firstName} ${lastName}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {firstName} {lastName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Group: {group?.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Age: {age}
        </Typography>
      </CardContent>
    </StyledCard>
  )
}

export default ChildCard
