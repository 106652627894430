import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { imageUrl } from '../../../Redux/Api/paths'
import { Typography, Grid } from '@mui/material'
import {
  StyledModal,
  ListContainer,
  EducatorList,
  EducatorItem,
  TextAndAvatar,
  Avatar,
  CustomAvatar,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'

import { Child } from '../../../Types/types'

interface Props {
  handleCloseModal: () => void
  childrenPerGroup: Child[] | undefined
}

export const DisplayChildrenPerGroup: React.FC<Props> = ({
  handleCloseModal,
  childrenPerGroup,
}) => {
  const { t } = useTranslation()

  return (
    <StyledModal open onClose={handleCloseModal}>
      <ListContainer>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {t(' Children')}
        </Typography>
        <EducatorList>
          {childrenPerGroup ? (
            childrenPerGroup?.map((child: Child) => (
              <EducatorItem key={child.id}>
                <TextAndAvatar>
                  <Avatar>
                    <CustomAvatar src={imageUrl + child.image} alt="child" />
                  </Avatar>
                  <Typography variant="h5" sx={{ marginRight: '25px' }}>
                    {child.firstName + ' ' + child.lastName}
                  </Typography>
                </TextAndAvatar>
              </EducatorItem>
            ))
          ) : (
            <p>{t('No children Assigned to this Group yet')}</p>
          )}
        </EducatorList>
      </ListContainer>
    </StyledModal>
  )
}
