import { Box } from '@mui/material'

interface AuthImgComponentProps {
  src: string
}

export const AuthImgComponent = ({ src }: AuthImgComponentProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'White',
        backgroundSize: 'contain',
        height: '100%',
        width: '50%',
        display: 'flex',
        objectFit: 'contain',
        backgroundPosition: 'center',
      }}
    />
  )
}
