import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import HomeImg from '../../../Assets/Images/HomeImg.jpg'
export const RootBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  width: '100%',
}))

export const OpacityBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
}))

export const BackGroundImageBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundImage: `url(${HomeImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'black',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100%',
  width: '100%',
  zIndex: 0,
}))

export const CitationBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  zIndex: 2,
}))

export const TypographyComponent = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  color: 'white',
}))

export const CitationTypographyComponent = styled(Typography)(({ theme }) => ({
  fontWeight: 100,
  color: 'white',
}))
