import React from 'react'
import ParentAlerts from '../../../Components/ParentComponents/ParentAlert'
import AlertList from '../../../Components/DashbordComponents/AlertList'
export default function AlertsPage() {
  return (
    <>
      <AlertList />
    </>
  )
}
