import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  backendPath,
  createActivity,
  educatorActivities,
  educatorProfile,
  getAllChildrenGroup,
  updateEducator,
} from './paths'
import { RootState } from '../Store/store'
import {
  Activity,
  Educator,
  IEducatorActivities,
  IGenericResponse,
  ListResponse,
} from '../../Types/types'

export const educatorApi = createApi({
  reducerPath: 'educatorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backendPath,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['educator'],
  endpoints: (builder) => ({
    educatorProfile: builder.query<IGenericResponse, number>({
      query: (id) => `${educatorProfile}/${id}`,
      providesTags: ['educator'],
    }),
    createActivity: builder.mutation<IGenericResponse, FormData>({
      query: (activity) => {
        return {
          url: createActivity,
          method: 'POST',
          body: activity,
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data')
            return headers
          },
        }
      },
      invalidatesTags: ['educator'],
    }),
    updateEducator: builder.mutation<IGenericResponse, Educator>({
      query: ({ id, ...rest }) => {
        return {
          url: `${updateEducator}/${id}`,
          method: 'PATCH',
          body: rest,
        }
      },
      invalidatesTags: ['educator'],
    }),
    childrenGroup: builder.query<IGenericResponse, number>({
      query: (educatorId) => `${getAllChildrenGroup}/${educatorId}`,
      providesTags: ['educator'],
    }),
    myActivities: builder.query<ListResponse<Activity>, IEducatorActivities>({
      query: ({ educatorId, page = 1 }) =>
        `${educatorActivities}/${educatorId}?page=${page}`,
      providesTags: ['educator'],
    }),
  }),
})

export const {
  useEducatorProfileQuery,
  useChildrenGroupQuery,
  useCreateActivityMutation,
  useMyActivitiesQuery,
  useUpdateEducatorMutation,
} = educatorApi
