import React from 'react'
import {
  InfoButton,
  CustomInfoIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { useTranslation } from 'react-i18next'

export const ActivityInfoButton: React.FC = () => {
  const { t } = useTranslation()
  return (
    <InfoButton variant="outlined" startIcon={<CustomInfoIcon />}>
      {t('showActivity')}
    </InfoButton>
  )
}
