import { UseFormRegisterReturn } from 'react-hook-form'
import { TextField, Button } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

interface IFileInputProps {
  label: string
  options: UseFormRegisterReturn
}

const StyledFileInput = styled(TextField)(({ theme }) => ({
  marginTop: '14px',
  '& .MuiInputBase-root': {
    border: '1px solid #d3d3d3',
    borderRadius: '4px',
    '&:hover': {
      borderColor: '#a9a9a9',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
    marginBottom: '8px',
    color: '#666',
  },
  '& .MuiInputBase-input': {
    paddingTop: '25px',
    paddingBottom: '14px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '14px',
    color: '#333',
  },
  '& .MuiInputBase-input:focus': {
    borderColor: '#4caf50',
    boxShadow: '0 0 0 2px rgba(76, 175, 80, 0.2)',
  },
  '& .MuiButton-root': {
    color: '#fff',
    background: '#4caf50',
    borderRadius: '4px',
    padding: '8px 16px',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      background: '#43a047',
    },
  },
  '& .MuiButton-contained.Mui-disabled': {
    background: '#d3d3d3',
    color: '#666',
  },
}))

function FileInput({ label, options }: IFileInputProps) {
  return (
    <StyledFileInput
      type="file"
      fullWidth
      label={label}
      {...options}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default FileInput
