import React, { useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { RadioGroup, Button, FormControlLabel, Radio } from '@mui/material'
import { sendTo } from '../../../Types/types'
import { styled } from '@mui/system'

interface IProps {
  label: string
  options: UseFormRegisterReturn
}

export const RadioButon: React.FC<IProps> = ({ label, options }) => {
  const [value, setValue] = React.useState('all')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }
  return (
    <RadioGroup
      aria-label="gender"
      name="gender"
      value={value}
      onChange={handleChange}
      row
    >
      <FormControlLabel
        value={sendTo.All}
        control={<Radio />}
        label="All"
        sx={{ mr: 2 }}
      />
      <FormControlLabel
        value={sendTo.Selected}
        control={<Radio />}
        label="Selected"
        sx={{ mr: 2 }}
      />
      <FormControlLabel
        value={sendTo.Specific}
        control={<Radio />}
        label="specific"
        sx={{ mr: 2 }}
      />
      <FormControlLabel value="specific" control={<Radio />} label="Specific" />
    </RadioGroup>
  )
}
