import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../Config/routes.enum'
import { Alert, Modal } from '@mui/material'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import { useTranslation } from 'react-i18next'
import { AddAlertFormConfig } from './AddAlert.constants'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IGroupInput, IAlert } from '../../../Types/types'
import { sendTo } from '../../../Types/types'
import { RadioButon } from '../../../Components/Inputs/RadioButtonInput/RadioButton'
import {
  CustomAddIcon,
  AddButton,
  AddModal,
  AddModalBox,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAddAlertMutation,
  useAllAlertsQuery,
} from '../../../Redux/Api/adminApi'

interface AddAlertFormProp {
  onCloseModalHandler: () => void
  onOpenModalHandler: () => void
}

export const AddAlertForm: React.FC<AddAlertFormProp> = ({
  onCloseModalHandler,
  onOpenModalHandler,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAlert>({
    defaultValues: {
      send_to: sendTo.All,
      user_id: undefined,
      user_ids: undefined,
    },
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [addAlert, { isSuccess, isError }] = useAddAlertMutation()
  const { refetch } = useAllAlertsQuery()

  //TOFIX: WORKS ONLY FOR BRODCAST
  const onSubmit: SubmitHandler<IAlert> = (alert) => {
    addAlert(alert)
  }
  useEffect(() => {
    if (isSuccess) {
      onCloseModalHandler()
      setTimeout(() => {
        navigate(`${routes.dashbordAdmin}/${routes.groupsPage}`)
      }, 2000)
      refetch()
    }
  }, [isSuccess, navigate, refetch, onCloseModalHandler])
  return (
    <AddModal open onClose={onCloseModalHandler}>
      <AddModalBox component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="title"
          options={{
            ...register('title', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.title.required),
              },
              minLength: {
                value: AddAlertFormConfig.title.minLength.value,
                message: t(AddAlertFormConfig.title.minLength.message),
              },
            }),
          }}
        />
        {errors.title && <Alert severity="error">{errors.title.message}</Alert>}
        <TextInput
          label="Message"
          options={{
            ...register('message', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.message.required),
              },
              minLength: {
                value: AddAlertFormConfig.message.minLength.value,
                message: t(AddAlertFormConfig.message.minLength.message),
              },
            }),
          }}
        />
        {errors.message && (
          <Alert severity="error">{errors.message.message}</Alert>
        )}
        <TextInput
          label="Start Date"
          options={{
            ...register('start_date', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.start_date.required),
              },
            }),
          }}
        />
        {errors.start_date && (
          <Alert severity="error">{errors.start_date.message}</Alert>
        )}
        <TextInput
          label="End Date"
          options={{
            ...register('end_date', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.end_date.required),
              },
            }),
          }}
        />
        {errors.end_date && (
          <Alert severity="error">{errors.end_date.message}</Alert>
        )}
        <RadioButon
          label="Send To"
          options={{
            ...register('send_to', {
              required: {
                value: true,
                message: t(AddAlertFormConfig.send_to.required),
              },
            }),
          }}
        />
        {errors.send_to && (
          <Alert severity="error">{errors.send_to.message}</Alert>
        )}
        <AddButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          startIcon={<CustomAddIcon />}
        >
          {t('add Alert')}
        </AddButton>
        {isSuccess && <Alert severity="success">{t('Alert created')}</Alert>}
        {isError && <Alert severity="error">{t('creation failed')}</Alert>}
      </AddModalBox>
    </AddModal>
  )
}
