import React, { useEffect, useState } from 'react'
import { Card, CardContent, Typography, TextField, Grid } from '@mui/material'
import { styled } from '@mui/system'
import ActivityCard from './ActivityCard'
import { useAppSelector } from '../../Redux/Store/store'
import { useMyActivitiesQuery } from '../../Redux/Api/educatorApi'
import { Activity } from '../../Types/types'
import Pagination from '../Shared/Pagination/Pagination'

const StyledCard = styled(Card)({
  width: '100%',
})
const ActivitiesList = () => {
  const educator = useAppSelector((state) => state.userSlice.educator)
  const educatorId = useAppSelector((state) => state.userSlice.educator?.id)
  const [page, setPage] = useState<number>(1)
  const {
    data: activities,
    isSuccess,
    isError,
    refetch,
  } = useMyActivitiesQuery({ educatorId: educatorId ?? 0, page })
  useEffect(() => {
    refetch()
  }, [activities, refetch])
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component="h2">
          {educator?.group?.name} Activities
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          sx={{ marginY: 2 }}
        />
        <Grid container spacing={3}>
          {activities?.data.data.map((activity: Activity) => (
            <Grid key={activity.id} item xs={12} md={4}>
              <ActivityCard activity={activity} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <Pagination
        onPageChange={setPage}
        currentPage={page}
        totalPages={activities?.data.last_page}
      />
    </StyledCard>
  )
}

export default ActivitiesList
