import React, { useState } from 'react'
import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import { Activity } from '../../Types/types'
import { imageUrl } from '../../Redux/Api/paths'
import ActivityDeleteModal from '../Shared/ActivityDeleteModel/ActivityDeleteModal'
import { ActityModalCOmponent } from '../DashbordComponents/ActityModalCOmponent'
const StyledCard = styled(Card)({
  width: '100%',
})

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '56.25%', // 16:9
})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  margin: 'auto',
  padding: '8px',
})

type ActivityCardProps = {
  activity: Activity
}

const ActivityCard = ({ activity }: ActivityCardProps) => {
  const { title, images } = activity
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openActivityModal, setOpenActivityModal] = useState<boolean>(false)
  const openActivityModalHandler = () => {
    setOpenActivityModal(true)
  }
  const closeActivityModalHandler = () => {
    setOpenActivityModal(false)
  }
  const openDeleteModalHandler = () => {
    setOpenDeleteModal(true)
  }
  const closeDeleteModalHandler = () => {
    setOpenDeleteModal(false)
  }
  return (
    <>
      {' '}
      <StyledCard>
        {images && (
          <StyledCardMedia image={imageUrl + images[0]} title={title} />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <ButtonContainer>
            <Button
              variant="contained"
              onClick={openActivityModalHandler}
              sx={{ bgcolor: '#3f51b5', color: '#fff' }}
            >
              Show
            </Button>
            <Button
              variant="contained"
              onClick={openDeleteModalHandler}
              sx={{ bgcolor: '#f44336', color: '#fff' }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: '#4caf50', color: '#fff' }}
            >
              Update
            </Button>
          </ButtonContainer>
        </CardContent>
      </StyledCard>
      {openDeleteModal && (
        <ActivityDeleteModal
          activity={activity}
          onCloseModal={closeDeleteModalHandler}
        />
      )}
      {openActivityModal && (
        <ActityModalCOmponent
          selectedActivity={activity}
          onCloseModal={closeActivityModalHandler}
        />
      )}
    </>
  )
}

export default ActivityCard
