import React from 'react'
import { makeStyles } from '@mui/styles'
import { TextareaAutosize } from '@mui/material'
import { ITextAreaInputProps } from './TextAreaInput.type'

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: '100%',
    resize: 'none',
    border: 'none',
    fontSize: 16,
    fontFamily: 'inherit',
    marginTop: '10px',
  },
}))

const TextArea: React.FC<ITextAreaInputProps> = ({ label, options }) => {
  const classes = useStyles()

  return (
    <TextareaAutosize
      aria-label={label}
      {...options}
      minRows={5}
      placeholder="Enter your text here"
      className={classes.textarea}
    />
  )
}

export default TextArea
