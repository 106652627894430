export const ResetPasswordFormConfig = {
  password: {
    required: 'ResetPasswordForm.password.required',
    minLength: {
      value: 6,
      message: 'ResetPasswordForm.password.minLength.message',
    },
    maxLength: {
      value: 12,
      message: 'ResetPasswordForm.password.maxLength.message',
    },
  },
  code: {
    required: 'ResetPasswordForm.code.required',
    minLength: {
      value: 4,
      message: 'ResetPasswordForm.code.minLength.message',
    },
    maxLength: {
      value: 4,
      message: 'ResetPasswordForm.code.maxLength.message',
    },
  },
}
