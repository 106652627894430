import React, { useEffect } from 'react'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import PasswordInput from '../../../Components/Inputs/PasswordInput/PasswordInput'
import { Alert, Avatar, Grid, Paper, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { SignUpFormConfig } from './SignUpForm.constants'
import { IUser } from '../../../Types/types'
import { useRegisterUserMutation } from '../../../Redux/Api/authApi'
import { routes } from '../../../Config/routes.enum'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import { SignUpFormButton } from '../../../Components/Shared/MuiStyledComponent/SignUpFomrStyledComponents'
import { useTranslation } from 'react-i18next'
const SignUpForm = () => {
  const { t } = useTranslation()
  const [registerUser, { isError, data, isSuccess }] = useRegisterUserMutation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>()
  const onSubmit: SubmitHandler<IUser> = async (inputData) => {
    await registerUser(inputData)
  }
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate(`${routes.loginPageRoute}`)
      }, 2000)
    }
  }, [isSuccess])
  return (
    <Paper
      elevation={0}
      sx={{ padding: 15 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Avatar sx={{ marginY: 2, backgroundColor: '#82a886' }}>
          <CardMembershipIcon />
        </Avatar>
        <Typography variant="h5">Sign up</Typography>
      </Grid>
      <TextInput
        label="FirstName"
        options={{
          ...register('firstName', {
            required: {
              value: true,
              message: t(SignUpFormConfig.firstName.required),
            },
            minLength: {
              value: SignUpFormConfig.firstName.minLength.value,
              message: t(SignUpFormConfig.firstName.minLength.message),
            },
          }),
        }}
      />
      {errors.firstName && (
        <Alert severity="error">{errors.firstName.message}</Alert>
      )}
      <TextInput
        label="LastName"
        options={{
          ...register('lastName', {
            required: {
              value: true,
              message: t(SignUpFormConfig.lastName.required),
            },
            minLength: {
              value: SignUpFormConfig.lastName.minLength.value,
              message: t(SignUpFormConfig.lastName.minLength.message),
            },
          }),
        }}
      />
      {errors.lastName && (
        <Alert severity="error">{errors.lastName.message}</Alert>
      )}
      <TextInput
        label="Email"
        options={{
          ...register('email', {
            required: {
              value: true,
              message: t(SignUpFormConfig.email.required),
            },
            minLength: {
              value: SignUpFormConfig.email.minLength.value,
              message: t(SignUpFormConfig.email.minLength.message),
            },
          }),
        }}
      />
      {errors.email && <Alert severity="error">{errors.email.message}</Alert>}
      <PasswordInput
        label="Password"
        options={{
          ...register('password', {
            required: {
              value: true,
              message: t(SignUpFormConfig.password.required),
            },
            minLength: {
              value: SignUpFormConfig.password.minLength.value,
              message: t(SignUpFormConfig.password.minLength.message),
            },
            maxLength: {
              value: SignUpFormConfig.password.maxLength.value,
              message: t(SignUpFormConfig.password.maxLength.message),
            },
          }),
        }}
      />
      {errors.password && (
        <Alert severity="error">{errors.password.message}</Alert>
      )}
      <SignUpFormButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Sign up
      </SignUpFormButton>
      {isSuccess && <Alert severity="success">{data?.message}</Alert>}
      {isError && <Alert severity="success">{data?.message}</Alert>}
    </Paper>
  )
}

export default SignUpForm
