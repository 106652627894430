import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const ParentGrid = styled(Grid)(({ theme }) => ({
  marginTop: '20px',
}))

export const AboutUsGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0 10px',
}))
export const AboutUsPaper = styled(Paper)(({ theme }) => ({
  padding: 10,
}))

export const OurMissionPaper = styled(Paper)(({ theme }) => ({
  width: '40vw',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 5,
}))

export const OurMissionGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
}))
