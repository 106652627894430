import { Box, Typography } from '@mui/material'
import Footer from '../Components/Footer/Footer'
import { RootBox } from '../Components/Shared/MuiStyledComponent/HomeImageStyledCOmponents'
import { OpacityBox } from '../Components/Shared/MuiStyledComponent/HomeImageStyledCOmponents'
import { BackGroundImageBox } from '../Components/Shared/MuiStyledComponent/HomeImageStyledCOmponents'
import { CitationBox } from '../Components/Shared/MuiStyledComponent/HomeImageStyledCOmponents'
import { TypographyComponent } from '../Components/Shared/MuiStyledComponent/HeaderStyledComponent'
import { CitationTypographyComponent } from '../Components/Shared/MuiStyledComponent/HomeImageStyledCOmponents'
import Header from '../Components/Header/Header'
const Home = () => {
  return (
    <>
      <RootBox>
        <OpacityBox />
        <BackGroundImageBox />
        <CitationBox>
          <TypographyComponent variant="h4" align="center">
            Our Responsibility:{' '}
            <b style={{ color: 'red' }}>Caring for Children</b>
          </TypographyComponent>
          <CitationTypographyComponent align="center" variant="body2">
            Your child is between safe hands
          </CitationTypographyComponent>
        </CitationBox>
      </RootBox>
      <Footer />
    </>
  )
}
export default Home
