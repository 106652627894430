import React from 'react'
import { ActivitiesList } from '../../../Components/DashbordComponents/ActivitiesList'

export const ActivitiesPage = () => {
  return (
    <>
      <ActivitiesList />
    </>
  )
}
