import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
} from '@mui/material'
import { Activity } from '../../Types/types'
import { imageUrl } from '../../Redux/Api/paths'
import {
  ActivityDialogContentText,
  ActivityModalCard,
  ActivityModalCardMedia,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
interface ActivityModalProps {
  selectedActivity: Activity
  onCloseModal: () => void
}

export const ActityModalCOmponent: React.FC<ActivityModalProps> = ({
  selectedActivity,
  onCloseModal,
}) => {
  const [currentPicture, setCurrentPicture] = useState<number>(0)

  const handlePrevPicture = () => {
    setCurrentPicture((prev) =>
      prev > 0
        ? prev - 1
        : selectedActivity.images && selectedActivity.images.length > 0
        ? selectedActivity.images.length - 1
        : 0
    )
  }

  const handleNextPicture = () => {
    setCurrentPicture((prev) =>
      selectedActivity.images && prev < selectedActivity.images.length - 1
        ? prev + 1
        : 0
    )
  }

  return (
    <Dialog open onClose={onCloseModal}>
      <DialogTitle>{selectedActivity.title}</DialogTitle>
      <DialogContent>
        <ActivityDialogContentText>
          {selectedActivity.description}
        </ActivityDialogContentText>
        <Grid container spacing={2}>
          {selectedActivity.images?.map((picture, index) => (
            <Grid item xs={6} sm={4} key={index}>
              <ActivityModalCard onClick={() => setCurrentPicture(index)}>
                <ActivityModalCardMedia
                  image={imageUrl + picture}
                  title={selectedActivity.title}
                />
              </ActivityModalCard>
            </Grid>
          ))}
        </Grid>
        <DialogActions>
          <Button onClick={handlePrevPicture}>Prev</Button>
          <Button onClick={handleNextPicture}>Next</Button>
        </DialogActions>
      </DialogContent>
      {selectedActivity.images && (
        <img
          src={imageUrl + selectedActivity.images[currentPicture]}
          alt={selectedActivity.title}
        />
      )}
    </Dialog>
  )
}
