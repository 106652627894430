import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../Config/routes.enum'
import { Alert, Modal } from '@mui/material'
import TextInput from '../../../Components/Inputs/TextInputComponent/TextInput'
import { useTranslation } from 'react-i18next'
import { addGroupFormConfig } from './AddGroup.constants'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IGroupInput, Group } from '../../../Types/types'
import {
  CustomAddIcon,
  AddButton,
  AddModal,
  AddModalBox,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAddGroupMutation,
  useAllGroupsQuery,
} from '../../../Redux/Api/adminApi'

interface AddGroupFormProp {
  onCloseModalHandler: () => void
  onOpenModalHandler: () => void
}

export const AddGroupForm: React.FC<AddGroupFormProp> = ({
  onCloseModalHandler,
  onOpenModalHandler,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IGroupInput>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [addGroup, { isSuccess, isError }] = useAddGroupMutation()
  const { refetch } = useAllGroupsQuery()

  const onSubmit: SubmitHandler<IGroupInput> = (group) => {
    addGroup(group)
  }
  useEffect(() => {
    if (isSuccess) {
      onCloseModalHandler()
      setTimeout(() => {
        navigate(`${routes.dashbordAdmin}/${routes.groupsPage}`)
      }, 2000)
      refetch()
    }
  }, [isSuccess, navigate, refetch, onCloseModalHandler])
  return (
    <AddModal open onClose={onCloseModalHandler}>
      <AddModalBox component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="groupName"
          options={{
            ...register('name', {
              required: {
                value: true,
                message: t(addGroupFormConfig.name.required),
              },
              minLength: {
                value: addGroupFormConfig.name.minLength.value,
                message: t(addGroupFormConfig.name.minLength.message),
              },
            }),
          }}
        />
        {errors.name && <Alert severity="error">{errors.name.message}</Alert>}
        <AddButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          startIcon={<CustomAddIcon />}
        >
          {t('add Group')}
        </AddButton>
        {isSuccess && <Alert severity="success">{t('Group created')}</Alert>}
        {isError && <Alert severity="error">{t('creation failed')}</Alert>}
      </AddModalBox>
    </AddModal>
  )
}
