import React from 'react'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/system'

export const FooterBoxComponent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#82a886',
  padding: 2,
  marginTop: 'auto',
  color: '#e6e8eb',
  justifyContent: 'center',
}))

export const CopyRightIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))
