import React from 'react'
import {
  useDeleteGroupMutation,
  useAllGroupsQuery,
} from '../../Redux/Api/adminApi'
import {
  DeleteButton,
  CustomDeleteIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { Group } from '../../Types/types'
import { useTranslation } from 'react-i18next'

interface ButtonProps {
  group: Group
}

export const DeleteGroupButton: React.FC<ButtonProps> = ({ group }) => {
  const [deleteGroup] = useDeleteGroupMutation()
  const { refetch } = useAllGroupsQuery()
  const { t } = useTranslation()
  return (
    <DeleteButton
      variant="outlined"
      startIcon={<CustomDeleteIcon />}
      onClick={() => {
        deleteGroup(group)
        refetch()
      }}
    >
      {t('deleteGroup')}
    </DeleteButton>
  )
}
