export const addEducatorFormConfig = {
  firstName: {
    required: 'addEducatorFormConfig.firstName.required',
    minLength: {
      value: 4,
      message: 'addEducatorFormConfig.firstName.minLength.message',
    },
  },
  lastName: {
    required: 'addEducatorFormConfig.lastName.required',
    minLength: {
      value: 4,
      message: 'addEducatorFormConfig.lastName.minLength.message',
    },
  },
  email: {
    required: 'addEducatorFormConfig.email.required',
    minLength: {
      value: 4,
      message: 'addEducatorFormConfig.email.minLength.message',
    },
  },
  password: {
    required: 'addEducatorFormConfig.password.required',
    minLength: {
      value: 4,
      message: 'addEducatorFormConfig.password.minLength.message',
    },
    maxLength: {
      value: 12,
      message: 'addEducatorFormConfig.password.maxLength',
    },
  },
  image: {
    required: false,
  },
}
