import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@mui/material'
import { routes } from '../../../Config/routes.enum'
import { useAppDispatch } from '../../../Redux/Store/store'
import {
  setIsAdmin,
  setIsEducator,
  setIsParent,
} from '../../../Redux/Redux-Slices/userSlice'

const LoginButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const loginAsParent = () => {
    dispatch(setIsParent())
    handleClose()
  }
  const loginAsAdmin = () => {
    dispatch(setIsAdmin())
    handleClose()
  }
  const loginAsEducator = () => {
    dispatch(setIsEducator())
    handleClose()
  }

  return (
    <>
      <Button onClick={handleClick} sx={{ color: 'whitesmoke' }}>
        Log in
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={NavLink}
          to={routes.loginPageRoute}
          onClick={loginAsParent}
        >
          Login as Parent
        </MenuItem>
        <MenuItem
          component={NavLink}
          to={routes.loginPageRoute}
          onClick={loginAsEducator}
        >
          Login as Educator
        </MenuItem>
        <MenuItem
          component={NavLink}
          to={routes.loginPageRoute}
          onClick={loginAsAdmin}
        >
          Login as Admin
        </MenuItem>
      </Menu>
    </>
  )
}

export default LoginButton
