import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Educator, IUser, IUserState } from '../../Types/types'
import { authApi } from '../Api/authApi'
import { saveToLocalStorage } from '../../Utils/saveToLocalStorage'
import { educatorApi } from '../Api/educatorApi'

const initialState: IUserState = {
  user: undefined,
  educator: undefined,
  token: undefined,
  resetCode: undefined,
  isParent: false,
  isAdmin: false,
  isEducator: false,
}

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setUser: (state, action: PayloadAction<IUserState>) => {
      saveToLocalStorage('user', action.payload.token)
      state.user = action.payload.user
      state.token = action.payload.token
    },
    //TOFIX: WHEN I DISPATCH THIS STATE DOES NOT UPDATE
    updateEducatorState: (state, action: PayloadAction<IUserState>) => {
      return {
        ...state,
        educator: action.payload.educator,
      }
    },
    logOut: (state) => {
      localStorage.clear()
      state.user = undefined
      state.educator = undefined
      state.token = undefined
    },
    setIsParent: (state) => {
      state.isParent = true
      state.isAdmin = false
      state.isEducator = false
    },
    setIsEducator: (state) => {
      state.isParent = false
      state.isAdmin = false
      state.isEducator = true
    },
    setIsAdmin: (state) => {
      state.isParent = false
      state.isAdmin = true
      state.isEducator = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          saveToLocalStorage('user', payload.data.token)
          state.user = payload.data.user
          state.token = payload.data.token
        }
      )
      .addMatcher(
        authApi.endpoints.loginEducator.matchFulfilled,
        (state, { payload }) => {
          saveToLocalStorage('educator', payload.data.token)
          state.educator = payload.data.educator
          state.token = payload.data.token
        }
      )
      .addMatcher(authApi.endpoints.logoutUser.matchFulfilled, (state) => {
        localStorage.removeItem('user')
        state.user = undefined
        state.educator = undefined
        state.token = undefined
        state.isAdmin = false
        state.isParent = false
        state.isEducator = false
      })
      .addMatcher(
        educatorApi.endpoints.updateEducator.matchFulfilled,
        (state, { payload }) => {
          state.educator = payload.data.educator
        }
      )
  },
})

export default userSlice.reducer
export const {
  setUser,
  logOut,
  setIsAdmin,
  setIsParent,
  setIsEducator,
  updateEducatorState,
} = userSlice.actions
