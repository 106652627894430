import React from 'react'
import {
  useAllEducatorsQuery,
  useDeleteEducatorMutation,
} from '../../Redux/Api/adminApi'
import { Educator } from '../../Types/types'
import {
  DeleteButton,
  CustomDeleteIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { useTranslation } from 'react-i18next'

interface ButtonProps {
  educator: Educator
}

export const DeleteEducatorButton: React.FC<ButtonProps> = ({ educator }) => {
  const [deleteEducator] = useDeleteEducatorMutation()
  const { refetch } = useAllEducatorsQuery()
  const { t } = useTranslation()
  return (
    <DeleteButton
      variant="outlined"
      startIcon={<CustomDeleteIcon />}
      onClick={() => {
        deleteEducator(educator)
        refetch()
      }}
    >
      {t('deleteEducator')}
    </DeleteButton>
  )
}
