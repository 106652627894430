import React from 'react'
import { Grid, Typography, IconButton, Box, Icon, Stack } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import HomeIcon from '@mui/icons-material/Home'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CopyrightIcon from '@mui/icons-material/Copyright'
import { FooterBoxComponent } from '../Shared/MuiStyledComponent/FooterStyledComponents'
import { CopyRightIconBox } from '../Shared/MuiStyledComponent/FooterStyledComponents'
import { SectionGrid } from '../Shared/MuiStyledComponent/FooterStyledComponents'
function Footer() {
  return (
    <Box component="footer">
      <FooterBoxComponent>
        <Grid container spacing={4}>
          <SectionGrid item xs={12} sm={4}>
            <Stack direction="column" spacing={1}>
              <Typography variant="h5">Find US</Typography>
              <Typography variant="body1" component="span">
                <Icon sx={{ marginRight: 1 }}>
                  {' '}
                  <LocalPhoneIcon />{' '}
                </Icon>
                +216 92 904 093
              </Typography>
              <Typography variant="body1" component="span">
                <Icon sx={{ marginRight: 1 }}>
                  {' '}
                  <EmailIcon />{' '}
                </Icon>
                SafeChild@gmail.com
              </Typography>
              <Typography variant="body1" component="span">
                <Icon sx={{ marginRight: 1 }}>
                  {' '}
                  <HomeIcon />{' '}
                </Icon>
                Tunisie , Sousse
              </Typography>
            </Stack>
          </SectionGrid>
          <SectionGrid item xs={12} sm={4}>
            <Stack direction="column" spacing={1}>
              <Typography variant="h5">Our Promises</Typography>
              <Typography variant="body1" component="span">
                <Icon sx={{ marginRight: 1 }}>
                  {' '}
                  <CreditCardIcon />{' '}
                </Icon>
                Flexible payment options
              </Typography>
            </Stack>
          </SectionGrid>
          <SectionGrid
            item
            direction="column"
            xs={12}
            sm={4}
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Coming Soon</Typography>
            </Grid>
            <Grid item>
              {' '}
              <Stack direction="column" spacing={1}>
                <IconButton>
                  <FacebookIcon />
                </IconButton>

                <IconButton>
                  <TwitterIcon />
                </IconButton>

                <IconButton>
                  <InstagramIcon />
                </IconButton>
              </Stack>
            </Grid>
          </SectionGrid>
          <Grid md={12} xs={12}>
            <CopyRightIconBox>
              <Icon sx={{ marginRight: 1 }}>
                <CopyrightIcon />
              </Icon>
              <Typography variant="body1" component="span">
                2023 SafeChild
              </Typography>
            </CopyRightIconBox>
          </Grid>
        </Grid>
      </FooterBoxComponent>
    </Box>
  )
}

export default Footer
