import React from 'react'
import { styled } from '@mui/system'
import {
  Grid,
  TableContainer,
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  IconButton,
  IconButtonProps,
  Modal,
  Checkbox,
  DialogContentText,
  CardMedia,
  Drawer,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import AddIcon from '@mui/icons-material/Add'

interface ISideBarListItemButton extends ListItemButtonProps {
  open?: boolean
}
interface ISideBarListItemIcon extends ListItemIconProps {
  open?: boolean
}
interface ISideBarListItemText extends ListItemTextProps {
  open?: boolean
}

interface ISideBarIconButton extends IconButtonProps {
  open?: boolean
}

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: 240,
  '& .MuiDrawer-paper': {
    backgroundColor: '#82a886',
    color: '#fff',
  },
  '& .MuiListItemIcon-root': {
    color: '#fff',
  },
  '& .MuiListItemText-root': {
    color: '#fff',
  },
  color: '#82a886',
  boxSizing: 'border-box',
  flexShrink: 0,
}))

export const TableGridComponent = styled(Grid)(({ theme }) => ({
  display: 'felx',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '60px',
}))

export const TableContainerComponent = styled(TableContainer)(({ theme }) => ({
  marginTop: '35px',
}))

export const ModalCardContent = styled(CardContent)(({ theme }) => ({
  flex: '1 0 auto',
  flexDirection: 'column',
  ml: 2,
}))

export const ActivitiesListGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '65px',
}))

export const ActivitiesListGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: '25px',
}))

export const ActivitiesListGridItemCard = styled(Card)(({ theme }) => ({
  height: '100%',
  maxWidth: 300,
}))

export const ActivitiesListBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
}))

export const DeleteButton = styled(Button)(({ theme }) => ({
  fontSize: '10px',
  color: '#82a886',
  outlineColor: '#82a886',
  borderColor: '#82a886',
  '&:hover': {
    backgroundColor: 'red',
    color: 'white',
    borderColor: '#82a886',
  },
}))

export const CustomDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: '#82a886',
}))

export const InfoButton = styled(Button)(({ theme }) => ({
  fontSize: '10px',
  color: '#82a886',
  outlineColor: '#82a886',
  borderColor: '#82a886',
  '&:hover': {
    backgroundColor: '#82a886',
    color: 'white',
    borderColor: '#82a886',
  },
}))

export const CustomInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: '#82a886',
}))
export const AddButton = styled(Button)(({ theme }) => ({
  fontSize: '10px',
  color: '#82a886',
  outlineColor: '#82a886',
  borderColor: '#82a886',
  marginTop: '20px',
  marginBottom: '20px',
  '&:hover': {
    backgroundColor: '#82a886',
    color: 'white',
    borderColor: '#82a886',
  },
}))

export const CustomAddIcon = styled(AddIcon)(({ theme }) => ({
  color: '#82a886',
}))

export const CustomAvatar = styled('img')(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: '10px',
}))

export const SideBarTypographie = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  color: '#dee0df',
}))

export const SideBarButton = styled(Button)(({ theme }) => ({
  color: '#dee0df',
  marginRight: '15px',
  fontWeight: 'bold',
  '&:hover': { backgroundColor: '#f6f6f6', color: '#000' },
}))

export const SideBarListItemButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 48,
  // justifyContent: open ? 'initial' : 'center',
  px: 2.5,
}))
export const SideBarListItemIcon = styled(ListItemIcon)<ISideBarListItemIcon>(
  ({ theme, open }) => ({
    minWidth: 0,
    mr: open ? 3 : 'auto',
    justifyContent: 'center',
  })
)
export const SideBarListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '10px',
}))

export const SideBarListItem = styled(ListItem)(({ theme }) => ({
  display: 'block',
}))

export const SideBarBox = styled(Box)(({ theme }) => ({ flexGrow: 1, p: 3 }))

export const AddModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const AddModalBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: '2rem',
  borderRadius: '4px',
  boxShadow: '0 3px 10px rgba(0,0,0,0.25)',
  maxWidth: '500px',
  margin: '0 auto',
}))

export const Avatar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  overflow: 'hidden',
  marginRight: '28px',
})

export const StyledCheckbox = styled(Checkbox)({
  marginLeft: 'auto',
  display: 'felx',
  color: '#82a886',
  alignItems: 'center',
  '&.Mui-checked': {
    color: '#82a886',
  },
})
export const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  Maxwidth: '500px',
}))

export const ListContainer = styled('div')(({ theme }) => ({
  backgroundColor: ' #fff',
  borderRadius: '8px',
  Maxwidth: '500px',
}))

export const EducatorList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  maxHeight: '400px',
  overflowY: 'auto',
}))

export const EducatorItem = styled('li')(({ theme }) => ({
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    marginRight: '16px',
  },
  cursor: 'pointer',
  padding: '12px',
  borderRadius: '8px',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}))

export const TextAndAvatar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '25px',
})

export const CardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  maxWidth: '350px',
  bgcolor: '#F5F5F5',
  maxHeight: '400px',
  height: '50%',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
  },
}))

export const NumberDisplay = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  fontWeight: 'bold',
  marginTop: 16,
}))

export const HomeComponentGridRoot = styled(Grid)(({ theme }) => ({
  marginLeft: '20px',
  marginRight: '20px',
  marginTop: '85px',
}))

export const HomeComponentGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
}))

export const HomeComponentGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: '10px',
}))

export const ChartGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  marginTop: '20px',
  alignItems: 'center',
}))

export const ActivityDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    marginBottom: '20px',
  })
)

export const ActivityModalCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: '0 auto',
}))

export const ActivityModalCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: '56.25%',
}))

export const StyledPaginationButton = styled(Button)(({ theme }) => ({
  marginRight: '5px',
  backgroundColor: '#82a886',
  color: '#e8e8e8',
  '&:hover': {
    backgroundColor: '#82a886',
  },
}))

export const PaginationBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '2rem',
  marginBottom: '2rem',
})
