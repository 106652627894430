export const SignUpFormConfig = {
  firstName: {
    required: 'SignUpForm.firstName.required',
    minLength: { value: 3, message: 'SignUpForm.firstName.minLength.message' },
  },
  lastName: {
    required: 'SignUpForm.lastName.required',
    minLength: { value: 3, message: 'SignUpForm.lastName.minLength.message' },
  },
  email: {
    required: 'SignUpForm.email.required',
    minLength: { value: 6, message: 'SignUpForm.email.minLength.message' },
  },
  password: {
    required: 'SignUpForm.password.required',
    minLength: {
      value: 6,
      message: 'SignUpForm.password.minLength.message',
    },
    maxLength: {
      value: 12,
      message: 'SignUpForm.password.maxLength.message',
    },
  },
}
