import { Grid, Paper, Box, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import { LoginForm } from '../Features/Auth/LoginForm/LoginForm'
import Parents from '../Assets/Images/Parents.jpg'
import Educator from '../Assets/Images/Educ.jpg'
import Admin from '../Assets/Images/Admin.jpg'
import { useAppSelector } from '../Redux/Store/store'
import { AuthImgComponent } from '../Components/Shared/AuthImgComponent/AuthImgComponent'
const SignIn = () => {
  const isParent = useAppSelector((state) => state.userSlice.isParent)
  const isAdmin = useAppSelector((state) => state.userSlice.isAdmin)
  const isEducator = useAppSelector((state) => state.userSlice.isEducator)
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const paperStyle = {
    height: '100%',
    width: isMatch ? '100%' : '50%',
    marginLeft: 'auto',
    marginX: 10,
  }

  return (
    <Grid container>
      {!isMatch && isAdmin && <AuthImgComponent src={Admin} />}
      {!isMatch && isParent && <AuthImgComponent src={Parents} />}
      {!isMatch && isEducator && <AuthImgComponent src={Educator} />}
      <Paper elevation={0} style={paperStyle}>
        <LoginForm />
      </Paper>
    </Grid>
  )
}
export default SignIn
