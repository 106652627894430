import React from 'react'
import SignUpForm from '../Features/Auth/SignUpForm/SignUpForm'
import { Box, Grid, useTheme, useMediaQuery, Paper } from '@mui/material'
import { AuthImgComponent } from '../Components/Shared/MuiStyledComponent/AuthImgComponent'

const SignUp = () => {
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid container>
      {!isMatch && <AuthImgComponent />}
      <Paper
        elevation={0}
        style={{
          padding: 5,
          height: '100%',
          width: isMatch ? '100%' : '50%',
          marginLeft: 'auto',
        }}
      >
        <SignUpForm />
      </Paper>
    </Grid>
  )
}

export default SignUp
