import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { adminApi } from '../Api/adminApi'
import { authApi } from '../Api/authApi'
import { parentApi } from '../Api/parentApi'
import { educatorApi } from '../Api/educatorApi'
import userReducer from '../Redux-Slices/userSlice'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, userReducer)
export const store = configureStore({
  reducer: {
    userSlice: persistedReducer,
    [authApi.reducerPath]: authApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [parentApi.reducerPath]: parentApi.reducer,
    [educatorApi.reducerPath]: educatorApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      adminApi.middleware,
      parentApi.middleware,
      educatorApi.middleware
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const persistor = persistStore(store)
