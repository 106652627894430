export const AddChildFormConfig = {
  firstName: {
    required: 'AddChildFormConfig.firstName.required',
    minLength: {
      value: 4,
      message: 'AddChildFormConfig.firstName.minLength.message',
    },
  },
  lastName: {
    required: 'AddChildFormConfig.lastName.required',
    minLength: {
      value: 4,
      message: 'AddChildFormConfig.lastName.minLength.message',
    },
  },
  age: {
    required: 'AddChildFormConfig.age.required',
    min: {
      value: 4,
      message: 'AddChildFormConfig.age.min.message',
    },
    max: {
      value: 10,
      message: 'AddChildFormConfig.age.max.message',
    },
  },
  image: {
    required: false,
  },
  allergy: {
    required: false,
  },
  disease: {
    required: false,
  },
}
