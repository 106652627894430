import React from 'react'
import {
  useAllActivitiesQuery,
  useDeleteActivityMutation,
} from '../../Redux/Api/adminApi'
import { Activity } from '../../Types/types'
import { useTranslation } from 'react-i18next'
import {
  DeleteButton,
  CustomDeleteIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'

interface ButtonProps {
  activity: Activity
}

export const ActivityDeleteButton: React.FC<ButtonProps> = ({ activity }) => {
  const { t } = useTranslation()
  const [deleteActivity] = useDeleteActivityMutation()
  const { refetch } = useAllActivitiesQuery()
  return (
    <DeleteButton
      variant="outlined"
      startIcon={<CustomDeleteIcon />}
      onClick={() => {
        deleteActivity(activity)
        refetch()
      }}
    >
      {t('deleteActivity')}
    </DeleteButton>
  )
}
