import React from 'react'
import { Grid, Typography, Paper } from '@mui/material'
import { ParentGrid } from '../Components/Shared/MuiStyledComponent/AboutUsStyledCOmponent'
import { AboutUsGrid } from '../Components/Shared/MuiStyledComponent/AboutUsStyledCOmponent'
import { AboutUsPaper } from '../Components/Shared/MuiStyledComponent/AboutUsStyledCOmponent'
import { OurMissionPaper } from '../Components/Shared/MuiStyledComponent/AboutUsStyledCOmponent'
import { OurMissionGrid } from '../Components/Shared/MuiStyledComponent/AboutUsStyledCOmponent'
const AboutUs = () => {
  return (
    <ParentGrid container spacing={2}>
      <AboutUsPaper elevation={0}>
        <AboutUsGrid item xs={12}>
          <Typography
            sx={{ textAlign: 'center', paddingBottom: 3 }}
            variant="h4"
          >
            About Us
          </Typography>
          <Typography variant="body1">
            At <strong>SafeChild</strong> Nursery School, we are passionate
            about providing children with a safe, nurturing, and stimulating
            environment where they can grow and learn. Our journey started over
            20 years ago, when our founder, Jane Smith, realized that there was
            a need for high-quality childcare services in our community. From
            humble beginnings, we have grown into a thriving nursery school with
            a team of experienced and dedicated teachers and staff. Our
            curriculum is based on the latest research in child development and
            early childhood education, and we strive to provide a well-rounded
            program that supports childrens physical, social, emotional, and
            cognitive development. We believe that every child is unique, and we
            work closely with parents to create a personalized learning plan for
            each child. Our goal is to help children develop the skills and
            confidence they need to succeed in school and in life. Thank you for
            considering ABC Nursery School as your partner in your child s early
            education. We look forward to welcoming you to our community!
          </Typography>
        </AboutUsGrid>
      </AboutUsPaper>
      <OurMissionGrid item xs={12}>
        <OurMissionPaper elevation={0}>
          <Typography variant="h5" sx={{ paddingBottom: 3 }}>
            Our Mission
          </Typography>
          <Typography variant="body1">
            At <strong>SafeChild</strong> Nursery School, our mission is to
            provide children with a nurturing and engaging environment where
            they can explore, learn, and grow. We believe that every child
            deserves the best possible start in life, and we are committed to
            providing high-quality early childhood education that sets the
            foundation for future success. Our program is designed to promote
            children s social, emotional, cognitive, and physical development.
            We believe in creating a welcoming and inclusive community where
            children feel valued and respected, and where they can develop a
            love of learning that lasts a lifetime. We are dedicated to
            providing a safe and secure environment where children can explore
            and discover the world around them. Our team of experienced and
            caring teachers and staff are committed to creating a positive and
            supportive learning environment that fosters curiosity, creativity,
            and critical thinking.
          </Typography>
        </OurMissionPaper>
      </OurMissionGrid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ marginTop: 2 }}>
          Our Team
        </Typography>
      </Grid>
    </ParentGrid>
  )
}

export default AboutUs
