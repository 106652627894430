import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  IGenericResponse,
  IUser,
  ICredentials,
  IForgetPassword,
} from '../../Types/types'
import {
  backendPath,
  loginPath,
  logOutPath,
  signUpPath,
  forgetPasswordPath,
  oneTimePasswordPath,
  resetPasswordPath,
  loginEducatorPath,
} from './paths'
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: backendPath }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    registerUser: builder.mutation<IGenericResponse, IUser>({
      query: (data) => ({
        url: signUpPath,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    loginUser: builder.mutation<IGenericResponse, ICredentials>({
      query: (data) => ({
        url: loginPath,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    loginEducator: builder.mutation<IGenericResponse, ICredentials>({
      query: (data) => ({
        url: loginEducatorPath,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    logoutUser: builder.mutation<IGenericResponse, void>({
      query: () => ({
        url: logOutPath,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    forgetPassword: builder.mutation<IGenericResponse, IForgetPassword>({
      query: (data) => ({
        url: forgetPasswordPath,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    oneTimePasswordPath: builder.mutation<IGenericResponse, IForgetPassword>({
      query: (data) => ({
        url: oneTimePasswordPath,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    resetPasswordPath: builder.mutation<IGenericResponse, IForgetPassword>({
      query: (data) => ({
        url: resetPasswordPath,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
})

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useForgetPasswordMutation,
  useOneTimePasswordPathMutation,
  useResetPasswordPathMutation,
  useLoginEducatorMutation,
} = authApi
