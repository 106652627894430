import { useState } from 'react'
import { DeleteButtonComponent } from './DeleteParentButtonComponent'
import { useAllParentsQuery } from '../../Redux/Api/adminApi'
import { IUser } from '../../Types/types'
import { useTranslation } from 'react-i18next'
import Pagination from '../Shared/Pagination/Pagination'
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableCell,
} from '@mui/material'
import {
  TableGridComponent,
  TableContainerComponent,
  AddButton,
  CustomAddIcon,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import { AddChildForm } from '../../Features/Dashbord/AddChild/AddChildForm'

export const Parents = () => {
  const [page, setPage] = useState<number>(1)
  const {
    data: parents,
    isSuccess,
    isError,
    isFetching,
  } = useAllParentsQuery(page)
  const { t } = useTranslation()
  const [openAddChildModel, setOpenAddChildModel] = useState<boolean>(false)

  const [parentId, setParentId] = useState<number | undefined>(undefined)

  const openAddChildModelHandler = (parent: IUser) => {
    setOpenAddChildModel(true)
    setParentId(parent.id)
  }
  const closeAddChildModelHandler = () => {
    setOpenAddChildModel(false)
  }
  return (
    <TableGridComponent container>
      <Grid item xs={10} sm={12} md={10}>
        {isError && <p>{t('error')}</p>}
        {isFetching && <p>{t('fetchingData')}</p>}
        <Paper>
          <TableContainerComponent>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>FirstName</TableCell>
                  <TableCell>LastName</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Children Number</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Add Child</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess && Array.isArray(parents.data.data) ? (
                  parents.data.data.map((user: IUser) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.nbChildren}</TableCell>
                      <TableCell>
                        <DeleteButtonComponent user={user} />
                      </TableCell>
                      <TableCell>
                        <AddButton
                          size="large"
                          type="submit"
                          variant="outlined"
                          startIcon={<CustomAddIcon />}
                          onClick={() => {
                            openAddChildModelHandler(user)
                          }}
                        >
                          {t('Add Child')}
                        </AddButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <p>{t('noParents')}</p>
                )}
              </TableBody>
            </Table>
            <Pagination
              onPageChange={setPage}
              currentPage={page}
              totalPages={parents?.data.last_page}
            />
          </TableContainerComponent>
        </Paper>
        {openAddChildModel && (
          <AddChildForm
            onCloseModelHandler={closeAddChildModelHandler}
            idParent={parentId}
          />
        )}
      </Grid>
    </TableGridComponent>
  )
}
