import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { imageUrl } from '../../../Redux/Api/paths'
import {
  Typography,
  Alert,
  Grid,
  Box,
  Table,
  TableRow,
  TableCell,
} from '@mui/material'
import {
  AddButton,
  CustomAddIcon,
  Avatar,
  StyledCheckbox,
  StyledModal,
  ListContainer,
  EducatorList,
  EducatorItem,
  TextAndAvatar,
  CustomAvatar,
} from '../../../Components/Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
import {
  useAllChildrenQuery,
  useAssignChildMutation,
} from '../../../Redux/Api/adminApi'
import { Child, IAssignChild } from '../../../Types/types'
import Pagination from '../../../Components/Shared/Pagination/Pagination'

interface Props {
  handleCloseModal: () => void
  groupId: number | undefined
}

export const AssignChildForm: React.FC<Props> = ({
  handleCloseModal,
  groupId,
}) => {
  const { t } = useTranslation()
  const [selectedChild, setSelectedChild] = useState<Child | null>(null)
  const [page, setPage] = useState<number>(1)
  const { data: children, refetch } = useAllChildrenQuery(page)
  const [assignChild, { isSuccess, isError }] = useAssignChildMutation()

  const handleChildSelection = (child: Child) => {
    setSelectedChild(child)
  }
  const assingChildHandler = (data: IAssignChild) => {
    assignChild(data)
  }

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal()
      refetch()
    }
  }, [isSuccess, refetch, handleCloseModal])
  return (
    <StyledModal open onClose={handleCloseModal}>
      <Grid
        container
        sx={{ diplay: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item xs={12} sm={12} md={10}>
          <ListContainer>
            <Typography marginLeft={4} variant="h5">
              {t('Select a Child')}
            </Typography>
            <EducatorList>
              {' '}
              {children?.data.data.map((child: Child) => (
                <EducatorItem
                  key={child.id}
                  onClick={() => {
                    handleChildSelection(child)
                  }}
                >
                  <TextAndAvatar>
                    <CustomAvatar src={imageUrl + child.image} alt="child" />
                    <Typography variant="h5" sx={{ marginRight: '25px' }}>
                      {child.firstName + ' ' + child.lastName}
                    </Typography>
                  </TextAndAvatar>

                  <StyledCheckbox
                    checked={selectedChild?.id === child.id}
                    color="primary"
                    disableRipple
                  />
                </EducatorItem>
              ))}
              <Box mt={2} mb={4}>
                {' '}
                <Pagination
                  onPageChange={setPage}
                  currentPage={page}
                  totalPages={children?.data.last_page}
                />
              </Box>
              <AddButton
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                startIcon={<CustomAddIcon />}
                onClick={() => {
                  assingChildHandler({
                    groupId,
                    childId: selectedChild?.id,
                  })
                }}
              >
                {t('Confirm')}
              </AddButton>
            </EducatorList>
            {isSuccess && (
              <Alert severity="success">
                {t('Child assigned successfully')}
              </Alert>
            )}
            {isError && (
              <Alert severity="error">{t('Failed to assign Child')}</Alert>
            )}
          </ListContainer>
        </Grid>
      </Grid>
    </StyledModal>
  )
}
