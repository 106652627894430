import React from 'react'
import { Button } from '@mui/material'

interface IButtonProps {
  text: string
  onClick?: () => void
  disabled?: boolean
}
const SubmitButton: React.FC<IButtonProps> = ({
  text,
  onClick,
  disabled = false,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      sx={{
        backgroundColor: '#82a886',
        '&:hover': {
          backgroundColor: '#6ABC6A',
        },
        marginY: 2,
      }}
    >
      {text}
    </Button>
  )
}
export default SubmitButton
