export enum routes {
  loginPageRoute = '/login',
  signupPageRoute = '/signup',
  aboutUsPageRoute = '/about-us',
  sevicesPageRoute = '/services',
  pricingPageRoute = '/pricing',
  homePageRoute = '/',
  contactPAgeRoute = '/contact',
  forgetPasswordPageRoute = '/forget-password',
  resetPasswordPageRoute = '/reset-password',
  oneTimePasswordPageRoute = '/one-time-password',
  dashbordAdmin = '/admin/dashbord',
  parentsPage = 'parents',
  childrenPage = 'children',
  educatorsPage = 'educators',
  groupsPage = 'groups',
  activitiesPage = 'activities',
  alertsPage = 'alerts',
  homePageContent = 'home',
  parentHomePage = '/parent/home',
  parentChildrenPage = '/Mychildren',
  parentChildActivities = '/child/activities',
  parentAlerts = '/parent/alerts',
  educatorHomePage = '/educator/Home',
  educatorSettingPage = '/educator/Setting',
  educatorMyGroupPage = 'educator/group',
  educatorMyChildrenPage = 'educator/MyChildren',
}
