import { TextField } from '@mui/material'
import { ITextInputProps } from './TextInput.type'

function NumberInput({ label, options }: ITextInputProps) {
  return (
    <TextField
      fullWidth
      type="number"
      variant="standard"
      label={label}
      {...options}
      sx={{ marginY: 2 }}
    />
  )
}

export default NumberInput
