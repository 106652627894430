import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Grid } from '@mui/material'
import { useAllEducatorsQuery } from '../../Redux/Api/adminApi'
import { Educator } from '../../Types/types'
import { DeleteEducatorButton } from './DeleteEducatorButtonCOmponent'
import { imageUrl } from '../../Redux/Api/paths'
import { useTranslation } from 'react-i18next'
import {
  TableGridComponent,
  TableContainerComponent,
  CustomAvatar,
} from '../Shared/MuiStyledComponent/DashbordMuiComponents/DshbordStyledComponent'
export const Educators = () => {
  const { t } = useTranslation()
  const { data, isSuccess, isError, isFetching } = useAllEducatorsQuery()

  return (
    <TableGridComponent container>
      <Grid item xs={10} sm={10} md={10}>
        {isError && <p>{t('error')}</p>}
        {isFetching && <p>{t('fetchingData')}</p>}
        <Paper>
          <TableContainerComponent>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>FirstNAme</TableCell>
                  <TableCell>LastName</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Education</TableCell>
                  <TableCell>Reviews</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess && Array.isArray(data.data) ? (
                  data.data.map((educator: Educator) => (
                    <TableRow key={educator.id}>
                      <TableCell>{educator.id}</TableCell>
                      <TableCell>
                        <CustomAvatar
                          src={imageUrl + educator.image}
                          alt="educator"
                        />
                      </TableCell>
                      <TableCell>{educator.firstName}</TableCell>
                      <TableCell>{educator.lastName}</TableCell>
                      <TableCell>{educator.email}</TableCell>
                      <TableCell>{educator.education}</TableCell>
                      <TableCell>{educator.reviews}</TableCell>
                      <TableCell>
                        <DeleteEducatorButton educator={educator} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <p>{t('noEducators')}</p>
                )}
              </TableBody>
            </Table>
          </TableContainerComponent>
        </Paper>
      </Grid>
    </TableGridComponent>
  )
}
