import React, { useRef, useState } from 'react'
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material'
import { styled } from '@mui/system'
import ChildCard from './ChildCard'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useAppSelector } from '../../Redux/Store/store'
import { useChildrenGroupQuery } from '../../Redux/Api/educatorApi'
import { Child } from '../../Types/types'

const StyledCard = styled(Card)({
  width: '100%',
  position: 'relative',
  overflowX: 'hidden',
})

const ScrollContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 0',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& > *': {
    flexShrink: 0,
    width: 200,
    margin: '0 16px',
  },
})

const RightScroll = styled(Box)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: 16,
})

const LeftScroll = styled(Box)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 16,
})

const ChildrenList = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(e.currentTarget.scrollLeft)
  }

  const handleScrollLeft = () => {
    if (containerRef.current) {
      const prevScrollPosition = containerRef.current.scrollLeft
      const newScrollPosition = prevScrollPosition - 200
      scrollTo(containerRef.current, prevScrollPosition, newScrollPosition)
      setScrollPosition(newScrollPosition)
    }
  }

  const handleScrollRight = () => {
    if (containerRef.current) {
      const prevScrollPosition = containerRef.current.scrollLeft
      const newScrollPosition = prevScrollPosition + 200
      scrollTo(containerRef.current, prevScrollPosition, newScrollPosition)
      setScrollPosition(newScrollPosition)
    }
  }

  const scrollTo = (element: HTMLDivElement, from: number, to: number) => {
    setIsScrolling(true)
    let start: any = null
    const duration = 300
    const step = (timestamp: number) => {
      if (!start) start = timestamp
      const progress = timestamp - start
      const percent = Math.min(progress / duration, 1)
      const newPosition = from + (to - from) * percent
      element.scrollTo(newPosition, 0)
      if (percent < 1) {
        window.requestAnimationFrame(step)
      } else {
        setIsScrolling(false)
      }
    }
    window.requestAnimationFrame(step)
  }
  const educator = useAppSelector((state) => state.userSlice.educator)
  const educatorId = useAppSelector((state) => state.userSlice.educator?.id)

  const {
    data: children,
    isSuccess,
    isError,
  } = useChildrenGroupQuery(educatorId!)
  return (
    <>
      <StyledCard>
        <LeftScroll>
          <IconButton onClick={handleScrollLeft} size="small">
            <KeyboardArrowLeftIcon />
          </IconButton>
        </LeftScroll>
        <CardContent>
          <Typography variant="h5" component="h2">
            {educator?.group?.name} Children
          </Typography>

          <ScrollContainer ref={containerRef} onScroll={handleScroll}>
            {children?.data.map((child: Child) => (
              <ChildCard key={child.id} child={child} />
            ))}
          </ScrollContainer>
        </CardContent>
        <RightScroll>
          <IconButton onClick={handleScrollRight} size="small">
            <KeyboardArrowRightIcon />
          </IconButton>
        </RightScroll>
      </StyledCard>
    </>
  )
}

export default ChildrenList
