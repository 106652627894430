import { TextField } from '@mui/material'
import { ITextInputProps } from './TextInput.type'

function TextInput({ label, options }: ITextInputProps) {
  return (
    <TextField
      fullWidth
      variant="standard"
      label={label}
      {...options}
      sx={{ marginY: 2 }}
    />
  )
}

export default TextInput
