import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../Config/routes.enum'
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Child } from '../../Types/types'
import { imageUrl } from '../../Redux/Api/paths'
import {
  StyledCard,
  StyledHeader,
  StyledTypography,
  StyledMedia,
  StyledCardContent,
} from '../Shared/MuiStyledComponent/ParentMuiComponents/ParentMuiComponents'

interface Props {
  child: Child | undefined
}

export default function ChildCard({ child }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleActivitiesClick = () => {
    console.log('Activities clicked')
    handleClose()
  }

  const handleShowProfileClick = () => {
    console.log('Show Profile clicked')
    handleClose()
  }
  return (
    <StyledCard>
      <StyledHeader
        title={`${child?.firstName} ${child?.lastName}`}
        subheader={`${child?.age} years old, ${child?.group?.name} group`}
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <List>
                <ListItem
                  component={Link}
                  to={`${routes.parentChildActivities}/${child?.id}`}
                  button
                  onClick={handleActivitiesClick}
                >
                  <ListItemText primary={`${child?.firstName} activities`} />
                </ListItem>
              </List>
            </Popover>
          </>
        }
      />
      {child?.image && (
        <StyledMedia
          image={imageUrl + child?.image}
          title={`${child?.firstName}' '${child?.lastName}`}
        />
      )}
      <StyledCardContent>
        <StyledTypography variant="subtitle1" color="textPrimary">
          Educator: {child?.group?.educator?.firstName}
        </StyledTypography>
        {child?.allergy && (
          <StyledTypography variant="body2" color="textSecondary">
            Allergy: {child.allergy}
          </StyledTypography>
        )}
        {child?.disease && (
          <StyledTypography variant="body2" color="textSecondary">
            Disease: {child.disease}
          </StyledTypography>
        )}
      </StyledCardContent>
    </StyledCard>
  )
}
