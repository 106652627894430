import React, { useState } from 'react'
import ChildCard from '../../../Components/ParentComponents/MyChildren'
import { Grid } from '@mui/material'
import { useMyChildrenQuery } from '../../../Redux/Api/parentApi'
import { useAppSelector } from '../../../Redux/Store/store'
import { Child } from '../../../Types/types'
import { CenteredGrid } from '../../../Components/Shared/MuiStyledComponent/ParentMuiComponents/ParentMuiComponents'

export default function MyChildrenPage() {
  const userId = useAppSelector((state) => state.userSlice.user?.id)
  const {
    data: children,
    isSuccess,
    isFetching,
    isError,
  } = useMyChildrenQuery(userId!)
  console.log(children)
  return (
    <CenteredGrid container spacing={2}>
      {children?.data &&
        children.data.map((child: Child) => (
          <Grid item xs={10} sm={10} md={4} key={child.id} sx={{ height: 500 }}>
            <ChildCard child={child} />
          </Grid>
        ))}
    </CenteredGrid>
  )
}
