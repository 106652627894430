export interface IUser {
  id?: number
  firstName: string
  lastName: string
  password: string
  email: string
  roles?: string
  nbChildren?: number
}

export interface IGenericResponse {
  status: string
  message: string
  data: any
}

export interface ICredentials {
  email: string
  password: string
}

export interface IForgetPassword {
  email?: string
  code?: string
  password?: string
  confirmPassword?: string
}

export interface IEmailInput {
  email: string
}
export interface ICodeInput {
  code: string
}
export interface IPasswordInput {
  code: string
  password: string
}
export interface IGroupInput {
  name: string
}

export interface Child {
  id?: number
  image?: FileList
  firstName: string
  lastName: string
  age: number
  allergy?: string
  disease?: string
  parentId: number
  groupId?: number
  parent?: IUser
  group?: Group
  idParent?: number | undefined
}

export interface IRegisterChild {
  idParent: number | undefined
  formData: FormData
}

export interface IRegisterForm {
  firstName: string
  lastName: string
  age: number
  image: FileList | null
}

export interface Educator {
  id?: number
  image?: FileList | string
  firstName: string
  lastName: string
  password: string
  email: string
  education?: string
  reviews?: string
  roles?: string
  group?: Group
}

export interface Group {
  id?: number
  name: string
  educatorId?: number | null
  educator?: Educator | null
  children?: Child[] | []
}

export interface Activity {
  id?: number
  title: string
  description: string
  images?: string[]
  group?: Group
  created_at?: string
}

export interface IActivityInputFields {
  title: string
  description: string
  images: FileList
}

export interface IAssignChild {
  groupId: number | undefined
  childId: number | undefined
}

export interface IAssignEducator {
  groupId: number | undefined
  educatorId: number | undefined
}

export interface ListResponse<T> {
  status: string
  message: string
  data: {
    data: T[]
    current_page: number
    per_page: number
    total: number
    last_page: number
  }
}

export interface ChildActivitiesParameter {
  childId: number
  page: number
}

export const enum sendTo {
  All = 'all',
  Selected = 'selected',
  Specific = 'specific',
}

export interface IAlert {
  id?: number
  title: string
  message: string
  start_date: string
  end_date: string
  send_to?: sendTo
  user_id: number | undefined
  user_ids: number[] | undefined
}

export interface IEducatorActivities {
  educatorId: number
  page?: number
}

export interface SearchParams {
  title?: string
  description?: string
  created_from?: string
  created_to?: string
}

export interface IUserState {
  user: IUser | undefined
  educator: Educator | undefined
  token: string | undefined
  resetCode?: string
  isParent: boolean
  isAdmin: boolean
  isEducator: boolean
}
