import { useState } from 'react'
import { useDeleteActivityMutation } from '../../../Redux/Api/adminApi'
import { useMyActivitiesQuery } from '../../../Redux/Api/educatorApi'
import { useAppSelector } from '../../../Redux/Store/store'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { Activity } from '../../../Types/types'

interface IProps {
  activity: Activity
  onCloseModal: () => void
}

const ActivityDeleteModal: React.FC<IProps> = ({ activity, onCloseModal }) => {
  const educatorId = useAppSelector((state) => state.userSlice.educator?.id)
  const [page, setPage] = useState<number>(1)
  const [deleteActivity] = useDeleteActivityMutation()
  const { refetch } = useMyActivitiesQuery({
    educatorId: educatorId ?? 0,
    page,
  })
  const onDeleteHandler = () => {
    deleteActivity(activity)
    onCloseModal()
    refetch()
  }
  return (
    <>
      <Dialog open onClose={onCloseModal}>
        <DialogTitle>Delete Activity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this activity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button onClick={onDeleteHandler} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ActivityDeleteModal
